import React, { useEffect } from 'react';
import Navbar from '../components/layout/navbar';
import Footer from '../components/layout/footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import review from '../assets/imgs/reviewimg.png';
import business from '../assets/icons/business.svg';
import fitness from '../assets/icons/fitness.svg';
import frontend from '../assets/icons/frontend.svg';
import homedecor from '../assets/icons/homedecor.svg';
import marketing from '../assets/icons/marketing.svg';
import style from '../assets/icons/style.svg';
import { useDispatch } from 'react-redux';
import { setRedirectPath } from '../features/user/userSlice';
import searchIcon from '../assets/icons/search-icon.svg';
import profileIcon from '../assets/icons/user-icon.svg';
import dollarIcon from '../assets/icons/dollar-icon.svg';
import callIcon from '../assets/icons/call-icon.svg';
import ReasonCarousel from '../components/reasonCarousel';
import { Helmet } from 'react-helmet';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';

const index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const userName = queryParams.get('name');

  useEffect(() => {
    navigate('/login/expert')
  },[]);

  useEffect(() => {
    dispatch(setRedirectPath(true));
    if (userName) {
      sessionStorage.setItem('referralId', userName);
    }
  }, [userName]);

  return (
    <div className="mx-auto index">
      <Helmet>
        <title>Find Industry Experts on Zedintro.</title>
      </Helmet>
      <FacebookPixel />
      <div className="fixed top-0 left-0 right-0 w-full z-30 ">
        <Navbar />
      </div>

      <div className="indexbg flex items-center">
        <Container>
          <div className="one text-white">
            <div className="grid lg:grid-cols-1 items-center">
              <div className=" max-w-[43rem]">
                <h1 className="font-bold text-4xl lg:text-5xl xl:text-6xl">
                  Connect With Quality Industry Experts
                </h1>

                <p className="sm:text-base text-[1.05rem] lg:text-lg py-7 lg:py-8 xl:py-10">
                  Zedintro gives you{' '}
                  <span className="font-bold">access to industry experts</span>{' '}
                  for mentorship and guidance. Schedule sessions at your
                  convenience, make easy payments, and
                  <span className="font-bold">
                    {' '}
                    choose from vetted experts.
                  </span>
                </p>

                <Link to="/login/user">
                  <button className="sm:py-3 py-2 sm:px-16 px-5 rounded bg-white text-purple-900 text-sm lg:text-base font-medium">
                    Book An Expert
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="overflow-hidden">
        <Container>
          <div className="sm:my-32 my-24 relative">
            <h2 className="font-bold text-2xl lg:text-4xl leading-10 pb-10">
              What Makes ZedIntro Different?
            </h2>
            <div className="z-20 w-full">
              <ReasonCarousel />
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <section className="two pb-24">
          <h2 className="font-bold text-2xl lg:text-4xl text-center sm:text-left leading-10 pb-10">
            The Top Tier: Select an Expert Tailored to Your Needs
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 text-left">
            <div className="py-7 px-5 rounded-lg categories">
              <img
                src={frontend}
                alt="Find Industry Experts on Zedintro."
                className=""
              />
              <p className="text-xl font-semibold leading-7 py-2">Frontend</p>
              <p className="text-gray-500">
                Choose from our list of the top experts in a variety of topics
              </p>
            </div>
            <div className="py-7 px-5 rounded-lg categories">
              <img src={style} alt="style-image" className="" />
              <p className="text-xl font-semibold leading-7 py-2">
                Style & Beauty
              </p>
              <p className="text-gray-500">
                Choose from our list of the top experts in a variety of topics
              </p>
            </div>
            <div className="py-7 px-5 rounded-lg categories">
              <img src={fitness} alt="fitness-image" className="" />
              <p className="text-xl font-semibold leading-7 py-2">Fitness</p>
              <p className="text-gray-500">
                Choose from our list of the top experts in a variety of topics
              </p>
            </div>
            <div className="py-7 px-5 rounded-lg categories">
              <img src={homedecor} alt="homedecor-image" className="" />
              <p className="text-xl font-semibold leading-7 py-2">Home Decor</p>
              <p className="text-gray-500">
                Choose from our list of the top experts in a variety of topics
              </p>
            </div>
            <div className="py-7 px-5 rounded-lg categories">
              <img src={business} alt="business-image" className="" />
              <p className="text-xl font-semibold leading-7 py-2">
                Career & Business
              </p>
              <p className="text-gray-500">
                Choose from our list of the top experts in a variety of topics
              </p>
            </div>
            <div className="py-7 px-5 rounded-lg categories">
              <img src={marketing} alt="marketing-image" className="" />
              <p className="text-xl font-semibold leading-7 py-2">Marketing</p>
              <p className="text-gray-500">
                Choose from our list of the top experts in a variety of topics
              </p>
            </div>
          </div>

          {/* <div className="sm:mt-40 mt-20 lg:my-40 mx-auto">
          <h2 className="font-bold text-2xl lg:text-4xl leading-10 pb-16 text-center">
            Leading Experts From Impressive Establishments
          </h2>

          <div className="grid grid-cols-4 gap-8">
            <img src={layersImg} alt="logo" />
            <img src={sisyphusImg} alt="logo" />
            <img src={circoolesImg} alt="logo" />
            <img src={catalogImg} alt="logo" />
            <img src={quotientImg} alt="logo" />
            <img src={hourGlassImg} alt="logo" />
            <img src={commandImg} alt="logo" />
            <img src={circooImg} alt="logo" />
          </div>
        </div> */}
        </section>
      </Container>
      <section className="three bg-bgPurple sm:py-28 py-20 text-white">
        <Container>
          <div className="pb-12">
            <h2 className="font-bold text-2xl lg:text-[57px] pb-5 lg:pb-6">
              Get Started With 4 Easy Steps
            </h2>
            <p className="lg:pr-20 max-w-[70rem]">
              We&apos;re committed to helping you access the knowledge and
              expertise of industry leaders. Meet with an industry expert for 30
              minutes to 1 hour.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-4 sm:grid-cols-2 justify-between gap-12">
            <div className="">
              <div className="rounded-2xl pt-6 object-cover relative">
                <img
                  src={searchIcon}
                  alt="search"
                  className="object-center sm:w-20 w-16 sm:h-20 h-16"
                />
              </div>
              <h5 className="text-xl py-4 font-bold">
                Search from a selection of experts.
              </h5>
              <p>Decide on an expert based on your industry sector.</p>
            </div>

            <div className="">
              <div className="rounded-2xl pt-6">
                <img
                  src={profileIcon}
                  alt="search"
                  className="object-center sm:w-20 w-16 sm:h-20 h-16"
                />
              </div>
              <h5 className="text-xl font-bold py-4">
                Book a slot with an expert.
              </h5>
              <p>
                Make sure to choose your why; consultation, training, or
                mentorship.
              </p>
            </div>

            <div className="">
              <div className="rounded-2xl pt-6">
                <img
                  src={dollarIcon}
                  alt="search"
                  className="object-center sm:w-20 w-16 sm:h-20 h-16"
                />
              </div>
              <h5 className="text-xl font-bold py-4">Confirm payment.</h5>
              <p>
                This may vary depending on the duration preferred for the call.
              </p>
            </div>

            <div className="">
              <div className="rounded-2xl pt-6">
                <img
                  src={callIcon}
                  alt="search"
                  className="object-center sm:w-20 w-16 sm:h-20 h-16"
                />
              </div>
              <h5 className="text-xl font-bold py-4">Jump on the call.</h5>
              <p>
                Enjoy a rich and insightful conversation with your selected
                expert.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <section className="sm:py-28 py-20">
        <Container>
          <div className="grid lg:grid-cols-2 gap-10">
            <div className="self-center">
              <h3 className="text-xl lg:text-4xl text-center sm:text-left font-bold">
                Abundant mentorship opportunities, meaningful connections.
              </h3>
              <p className="py-6">
                Get exclusive access to experienced professionals who will guide
                you towards success. Make less errors on your way to a thriving
                career and prosperous business.
              </p>
              <button className="bg-bgPurple text-white shadow-md text-sm sm:text-base rounded px-4 sm:px-6 sm:py-3 py-2">
                Book Your Slot
              </button>
            </div>
            <img
              src={review}
              alt="review-image"
              className="sm:w-11/12 w-full"
            />
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};

export default index;
