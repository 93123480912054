import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/accountLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAvailability,
  // getProfile,
  profileAvailability,
  resetCalendar,
  updateProfile,
} from '../../features/experts/expertSlice';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import DropDown from '../../components/shared/DropDown';
import { BiChevronDown, BiMinus, BiPlus } from 'react-icons/bi';
import { Switch } from '@headlessui/react';

const timezoneOptions = [
  {
    id: 1,
    value: '(UTC-12:00) International Date Line West',
  },
  {
    id: 2,
    value: '(UTC-11:00) Coordinated Universal Time-11',
  },
  {
    id: 3,
    value: '(UTC-10:00) Hawaii',
  },
  {
    id: 4,
    value: '(UTC-09:00) Alaska',
  },
  {
    id: 5,
    value: '(UTC-08:00) Baja California',
  },
  {
    id: 6,
    value: '(UTC-08:00) Pacific Time (US and Canada)',
  },

  {
    id: 7,
    value: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    id: 8,
    value: '(UTC-07:00) Arizona',
  },
  {
    id: 9,
    value: '(UTC-07:00) Mountain Time (US and Canada)',
  },
  {
    id: 10,
    value: '(UTC-06:00) Central America',
  },
  {
    id: 11,
    value: '(UTC-06:00) Central Time (US and Canada)',
  },
  {
    id: 12,
    value: '(UTC-06:00) Saskatchewan',
  },
  {
    id: 13,
    value: '(UTC-06:00) Guadalajara, Mexico City, Monterey',
  },
  {
    id: 14,
    value: '(UTC-05:00) Bogota, Lima, Quito',
  },
  {
    id: 15,
    value: '(UTC-05:00) Indiana (East)',
  },
  {
    id: 16,
    value: '(UTC-05:00) Eastern Time (US and Canada)',
  },
  {
    id: 17,
    value: '(UTC-04:30) Caracas',
  },
  {
    id: 18,
    value: '(UTC-04:00) Atlantic Time (Canada)',
  },
  {
    id: 19,
    value: '(UTC-04:00) Asuncion',
  },
  {
    id: 20,
    value: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  },
  {
    id: 21,
    value: '(UTC-04:00) Cuiaba',
  },
  {
    id: 22,
    value: '(UTC-04:00) Santiago',
  },
  {
    id: 23,
    value: '(UTC-03:30) Newfoundland',
  },
  {
    id: 24,
    value: '(UTC-03:00) Brasilia',
  },
  {
    id: 25,
    value: '(UTC-03:00) Greenland',
  },
  {
    id: 26,
    value: '(UTC-03:00) Cayenne, Fortaleza',
  },
  {
    id: 27,
    value: '(UTC-03:00) Buenos Aires',
  },
  {
    id: 28,
    value: '(UTC-03:00) Montevideo',
  },
  {
    id: 29,
    value: '(UTC-02:00) Coordinated Universal Time-2',
  },
  {
    id: 30,
    value: '(UTC-01:00) Cape Verde',
  },
  {
    id: 31,
    value: '(UTC-01:00) Azores',
  },
  {
    id: 32,
    value: '(UTC+00:00) Casablanca',
  },
  {
    id: 33,
    value: '(UTC+00:00) Monrovia, Reykjavik',
  },
  {
    id: 34,
    value: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
  },
  {
    id: 35,
    value: '(UTC+00:00) Coordinated Universal Time',
  },
  {
    id: 36,
    value: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    id: 37,
    value: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    id: 38,
    value: '(UTC+01:00) West Central Africa',
  },
  {
    id: 39,
    value: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    id: 40,
    value: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    id: 41,
    value: '(UTC+01:00) Windhoek',
  },
  {
    id: 42,
    value: '(UTC+02:00) Athens, Bucharest, Istanbul',
  },
  {
    id: 43,
    value: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    id: 44,
    value: '(UTC+02:00) Cairo',
  },
  {
    id: 45,
    value: '(UTC+02:00) Damascus',
  },
  {
    id: 46,
    value: '(UTC+02:00) Amman',
  },
  {
    id: 47,
    value: '(UTC+02:00) Harare, Pretoria',
  },
  {
    id: 48,
    value: '(UTC+02:00) Jerusalem',
  },
  {
    id: 49,
    value: '(UTC+02:00) Beirut',
  },
  {
    id: 50,
    value: '(UTC+03:00) Baghdad',
  },
  {
    id: 51,
    value: '(UTC+03:00) Minsk',
  },
  {
    id: 52,
    value: '(UTC+03:00) Kuwait, Riyadh',
  },
  {
    id: 53,
    value: '(UTC+03:00) Nairobi',
  },
  {
    id: 54,
    value: '(UTC+03:30) Tehran',
  },
  {
    id: 55,
    value: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
  },
  {
    id: 56,
    value: '(UTC+04:00) Tbilisi',
  },
  {
    id: 57,
    value: '(UTC+04:00) Yerevan',
  },
  {
    id: 58,
    value: '(UTC+04:00) Abu Dhabi, Muscat',
  },
  {
    id: 59,
    value: '(UTC+04:00) Baku',
  },
  {
    id: 60,
    value: '(UTC+04:00) Port Louis',
  },
  {
    id: 61,
    value: '(UTC+04:30) Kabul',
  },
  {
    id: 62,
    value: '(UTC+05:00) Tashkent',
  },
  {
    id: 63,
    value: '(UTC+05:00) Islamabad, Karachi',
  },
  {
    id: 64,
    value: '(UTC+05:30) Sri Jayewardenepura Kotte',
  },
  {
    id: 65,
    value: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    id: 66,
    value: '(UTC+05:45) Kathmandu',
  },
  {
    id: 67,
    value: '(UTC+06:00) Astana',
  },
  {
    id: 68,
    value: '(UTC+06:00) Dhaka',
  },
  {
    id: 69,
    value: '(UTC+06:00) Yekaterinburg',
  },
  {
    id: 70,
    value: '(UTC+06:30) Yangon',
  },
  {
    id: 71,
    value: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    id: 72,
    value: '(UTC+07:00) Novosibirsk',
  },
  {
    id: 73,
    value: '(UTC+08:00) Krasnoyarsk',
  },
  {
    id: 74,
    value: '(UTC+08:00) Ulaanbaatar',
  },
  {
    id: 75,
    value: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    id: 76,
    value: '(UTC+08:00) Perth',
  },
  {
    id: 77,
    value: '(UTC+08:00) Kuala Lumpur, Singapore',
  },
  {
    id: 78,
    value: '(UTC+09:00) Irkutsk',
  },
  {
    id: 79,
    value: '(UTC+09:00) Seoul',
  },
  {
    id: 80,
    value: '(UTC+09:00) Osaka, Sapporo, Tokyo',
  },
  {
    id: 81,
    value: '(UTC+09:30) Darwin',
  },
  {
    id: 82,
    value: '(UTC+09:30) Adelaide',
  },
  {
    id: 83,
    value: '(UTC+10:00) Hobart',
  },
  {
    id: 84,
    value: '(UTC+10:00) Yakutsk',
  },
  {
    id: 85,
    value: '(UTC+10:00) Brisbane',
  },
  {
    id: 86,
    value: '(UTC+10:00) Guam, Port Moresby',
  },
  {
    id: 87,
    value: '(UTC+10:00) Canberra, Melbourne, Sydney',
  },
  {
    id: 88,
    value: '(UTC+11:00) Vladivostok',
  },
  {
    id: 89,
    value: '(UTC+11:00) Solomon Islands, New Caledonia',
  },
  {
    id: 90,
    value: '(UTC+12:00) Coordinated Universal Time+12',
  },
  {
    id: 91,
    value: '(UTC+12:00) Fiji, Marshall Islands',
  },
  {
    id: 92,
    value: '(UTC+12:00) Magadan',
  },
  {
    id: 93,
    value: '(UTC+12:00) Auckland, Wellington',
  },
  {
    id: 94,
    value: "(UTC+13:00) Nuku'alofa",
  },
  {
    id: 95,
    value: '(UTC+13:00) Samoa',
  },
];

const duration = [
  {
    id: 1,
    value: 15,
  },
  {
    id: 2,
    value: 30,
  },
  {
    id: 3,
    value: 45,
  },
  {
    id: 4,
    value: 60,
  },
  {
    id: 5,
    value: 75,
  },
  {
    id: 6,
    value: 90,
  },
];

const availabilityTime = [
  {
    dayOfWeek: 1,
    day: 'Monday',
    availability: [],
    checked: false,
  },
  {
    dayOfWeek: 2,
    day: 'Tuesday',
    availability: [],
    checked: false,
  },
  {
    dayOfWeek: 3,
    day: 'Wednesday',
    availability: [],
    checked: false,
  },
  {
    dayOfWeek: 4,
    day: 'Thursday',
    availability: [],
    checked: false,
  },
  {
    dayOfWeek: 5,
    day: 'Friday',
    availability: [],
    checked: false,
  },
  {
    dayOfWeek: 6,
    day: 'Saturday',
    availability: [],
    checked: false,
  },
  {
    dayOfWeek: 0,
    day: 'Sunday',
    availability: [],
    checked: false,
  },
];

const Availability = () => {
  const dispatch = useDispatch();
  const {
    expert,
    calendarStatus,
    error,
    // requestStatus,
    expertAvailability,
    getAvailabilityStatus,
    availabilityStatus,
  } = useSelector((state) => state.expert);
  const [timezone, setTimezone] = useState('');
  const [timeDuration, setTimeDuration] = useState(null);
  const [timeFrame, setTimeFrame] = useState(availabilityTime);
  const [touched, setTouched] = useState(false);
  const [durrTouch, setDurrTouch] = useState(false);

  useEffect(() => {
    if (calendarStatus === 'success') {
      toast.success('timezone updated successfully', {
        pauseOnFocusLoss: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (calendarStatus === 'failed') {
      toast.error(error.message, {
        pauseOnFocusLoss: false,
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return () => {
      dispatch(resetCalendar());
    };
  }, [calendarStatus]);

  useEffect(() => {
    // if (requestStatus === 'idle') {
    //   dispatch(getProfile());
    // }
    if (expert?.timezone) {
      setTimezone(expert.timezone);
    }
    setTimeDuration(expert?.minutesPerUnitSession);
  }, [expert]);

  const handleLocaleTime = (slot) => {
    const getLocaleTime = (timeStamp) => {
      const timeAllocation = timeStamp.split(':');
      const inputDate = new Date();
      const currTime = new Date(
        inputDate.setUTCHours(timeAllocation[0], timeAllocation[1], 0, 0),
      ).toLocaleTimeString();
      return currTime.slice(0, currTime.lastIndexOf(':')).length === 4 ? `0${currTime.slice(0, currTime.lastIndexOf(':'))}` : currTime.slice(0, currTime.lastIndexOf(':'));
    };

    return {
      start: getLocaleTime(slot.start),
      end: getLocaleTime(slot.end),
    };
  };

  useEffect(() => {
    if (getAvailabilityStatus === 'idle') {
      dispatch(profileAvailability());
    }

    if (expertAvailability) {
      let mutantTimestamp = [...timeFrame];

      for (const key in expertAvailability) {
        let availableSlot = expertAvailability[key].map((slot, idx) => {
          const slotTime = handleLocaleTime(slot);
          return { ...slotTime, id: idx };
        });

        mutantTimestamp = mutantTimestamp.map((timeInt) => {
          if (parseInt(timeInt.dayOfWeek) === parseInt(key)) {
            return { ...timeInt, availability: availableSlot, checked: true };
          } else {
            return timeInt;
          }
        });
      }

      setTimeFrame(mutantTimestamp);
    }
  }, [getAvailabilityStatus, expertAvailability]);

  const handleTimezone = (idx) => {
    setTimezone(timezoneOptions[idx - 1].value);
    if (timezoneOptions[idx - 1].value !== expert?.timezone) {
      setDurrTouch(true);
    }
  };

  const addTimezone = () => {
    const profileObj = {
      timezone: timezone,
      minutesPerUnitSession: timeDuration,
    };

    for (const key in profileObj) {
      if (!profileObj[key] || profileObj[key] === '') {
        delete profileObj[key];
      } else {
        profileObj[key] =
          typeof profileObj[key] === 'string'
            ? profileObj[key].trim()
            : profileObj[key];
      }
    }

    dispatch(updateProfile(profileObj));
  };

  const setDayCheck = (day) => {
    day.checked = !day.checked;
    if (day.checked) {
      let timeTrack = [
        {
          id: 0,
          start: 0,
          end: 0,
        },
      ];
      day.availability = timeTrack;
    } else {
      day.availability = [];
    }
    setTimeFrame(
      timeFrame.map((timeInt) =>
        timeInt.dayOfWeek === day.dayOfWeek ? day : timeInt,
      ),
    );
    setTouched(true);
  };

  const handleAddTime = (day) => {
    let timeTrack = [
      ...day.availability,
      {
        id: day.availability.length === 0 ? 0 : day.availability.length + 1,
        start: '',
        end: '',
      },
    ];
    day.availability = timeTrack;

    setTimeFrame(
      timeFrame.map((timeInt) =>
        timeInt.dayOfWeek === day.dayOfWeek ? day : timeInt,
      ),
    );
    setTouched(true);
  };

  const handleRemoveTime = (day, id) => {
    day.availability = day.availability.filter(
      (availableTime) => availableTime.id !== id,
    );
    if (day.availability.length === 0) {
      day.checked = false;
    }
    setTimeFrame(
      timeFrame.map((timeInt) =>
        timeInt.dayOfWeek === day.dayOfWeek ? day : timeInt,
      ),
    );
    setTouched(true);
  };

  const handleStartTime = (e, timePeriod, day) => {
    timePeriod.start = e.target.value;
    day.availability = day.availability.map((timeSlot) =>
      timeSlot.id === timePeriod.id ? timePeriod : timeSlot,
    );

    setTimeFrame(
      timeFrame.map((timeInt) =>
        timeInt.dayOfWeek === day.dayOfWeek ? day : timeInt,
      ),
    );
  };

  const handleEndTime = (e, timePeriod, day) => {
    timePeriod.end = e.target.value;
    day.availability = day.availability.map((timeSlot) =>
      timeSlot.id === timePeriod.id ? timePeriod : timeSlot,
    );

    setTimeFrame(
      timeFrame.map((timeInt) =>
        timeInt.dayOfWeek === day.dayOfWeek ? day : timeInt,
      ),
    );
    setTouched(true);
  };

  const getUtcEquivalent = (timeStamp) => {
    const [hours, minutes] = timeStamp.split(':');
    const parsedTime = new Date();
    parsedTime.setHours(hours);
    parsedTime.setMinutes(minutes);

    return parsedTime.toISOString();
  };

  const handleSaveAvailability = (e) => {
    e.preventDefault();
    let slots = [];
    if (durrTouch) {
      addTimezone();
    }

    if (touched) {
      timeFrame.forEach((timeSlot) => {
        if (timeSlot.availability.length > 0) {
          timeSlot.availability.forEach((slot) => {
            slots = [
              ...slots,
              {
                dayOfWeek: timeSlot.dayOfWeek,
                start: getUtcEquivalent(slot.start),
                end: getUtcEquivalent(slot.end),
              },
            ];
          });
        }
      });

      dispatch(addAvailability(slots));
    }
  };

  const handleDuration = (idx) => {
    setDurrTouch(true);
    setTimeDuration(idx);
  };

  return (
    <Layout>
      <div className="bg-white py-6 px-6 rounded-lg shadow-md">
        <h3 className="text-base font-medium" onClick={handleLocaleTime}>
          A session lasts for 15 minutes
        </h3>

        <div className="inline-flex flex-col md:w-[27rem] w-full mt-8 mb-3">
          <label htmlFor="timezone" className="mb-3 text-sm font-medium">
            Select your timezone
          </label>

          <DropDown
            options={timezoneOptions}
            className={
              'w-full border-2 max-h-80 overflow-scroll mt-12 font-light'
            }
            handleSelect={handleTimezone}
          >
            <div className="flex items-center relative py-2 sm:px-4 px-3 text-sm sm:text-base border-2 md:w-[22rem] w-full justify-between rounded-md shadow-sm">
              <p className="font-normal text-textLight">
                {' '}
                {timezone ? timezone : 'timezone'}
              </p>
              <BiChevronDown className="text-textLight font-semibold text-2xl" />
            </div>
          </DropDown>
        </div>

        <h3 className="text-base font-medium my-8">
          Set your minimum call limit
        </h3>

        <p className="text-sm font-medium my-3">Select Duration</p>

        <div className="mb-8 grid lg:grid-cols-6 grid-cols-3 gap-4 xl:gap-8">
          {duration.map((item) => (
            <button
              className={`sm:py-3 py-2 sm:px-7 px-3 cursor-pointer text-xs sm:text-sm md:text-base rounded-md border shadow-sm ${timeDuration === item.value ? ' bg-textPurple text-white' : ''
                }`}
              key={item.id}
              onClick={() => handleDuration(item.value)}
            >
              {item.value} MINS
            </button>
          ))}
        </div>

        <h3 className="text-base font-medium my-8">
          Set your daily and hourly availability
        </h3>

        <div className="">
          {timeFrame.map((item) => (
            <div
              className={`border shadow rounded md:py-5 py-4 md:px-6 px-4 flex flex-col lg:flex-row items-start my-2 ${item.checked ? '' : 'bg-[#F7F7FA]'
                }`}
              key={item.dayOfWeek}
            >
              <div className="flex flex-row-reverse lg:flex-row w-full lg:w-fit justify-between lg:justify-start items-center lg:space-x-4">
                <Switch
                  checked={item.checked}
                  onChange={() => setDayCheck(item)}
                  className={`${item.checked ? 'bg-[#6BB47B]' : 'bg-gray-200'}
          relative inline-flex sm:h-[27px] h-[20px] sm:w-[52px] w-[44px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${item.checked ? 'translate-x-6' : 'translate-x-0'
                      }
            pointer-events-none inline-block sm:h-[23px] h-[16px] sm:w-[23px] w-[16px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
                <p className="font-semibold">{item.day}</p>
              </div>
              {item.checked ? (
                <div className="ml-auto flex flex-col w-full lg:w-fit space-y-3 mt-4 lg:mt-0">
                  {item.availability.map((timeFrame, idx) => (
                    <div
                      className="flex flex-col sm:flex-row sm:items-center justify-between"
                      key={timeFrame.id}
                    >
                      <div className="flex items-center justify-between">
                        <div className="">
                          <input
                            className="border border-[#1B1B1E]/30 transition-all ease-in duration-1000 cursor-pointer rounded-md py-2 px-3 md:px-4 text-xs sm:text-sm md:text-base"
                            type="time"
                            id={'startTime'}
                            name={'startTime'}
                            onChange={(e) =>
                              handleStartTime(e, timeFrame, item)
                            }
                            value={timeFrame.start}
                          ></input>
                        </div>

                        <p className="md:mx-3 mx-1">to</p>

                        <div className="">
                          <input
                            className="border border-[#1B1B1E]/30 transition-all ease-in duration-1000 cursor-pointer rounded-md py-2 px-3 md:px-4 text-xs sm:text-sm md:text-base"
                            type="time"
                            id="endTime"
                            name="endTime"
                            onChange={(e) => handleEndTime(e, timeFrame, item)}
                            value={timeFrame.end}
                          ></input>
                        </div>
                      </div>

                      <div className="flex items-center mt-3 sm:mt-0">
                        <div className="md:w-7 w-5 md:h-7 h-5 border border-[#1B1B1E]/50 transition-all ease-in duration-1000 rounded-full md:mx-6 sm:mx-2 mr-2 flex items-center justify-center cursor-pointer">
                          <BiMinus
                            onClick={() => handleRemoveTime(item, timeFrame.id)}
                          />
                        </div>

                        <div
                          className={`md:w-7 w-5 md:h-7 h-5 ${idx + 1 !== item.availability.length
                            ? ''
                            : 'border border-[#1B1B1E]/70 transition-all ease-in duration-1000'
                            } rounded-full flex items-center justify-center cursor-pointer`}
                        >
                          <div
                            className={`${idx + 1 === item.availability.length
                              ? ''
                              : 'hidden'
                              }`}
                            onClick={() => handleAddTime(item)}
                          >
                            <BiPlus />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="ml-auto mt-3 sm:mt-0">Not available</p>
              )}
            </div>
          ))}
        </div>

        <button
          onClick={handleSaveAvailability}
          className={`py-2 mt-12 border w-full rounded shadow flex justify-center gap-4 ${touched || durrTouch
            ? 'bg-textPurple text-white'
            : 'bg-gray-200 text-gray-400'
            } `}
        >
          {(calendarStatus === 'loading' ||
            availabilityStatus === 'loading') && (
              <ReactLoading
                type="cylon"
                color="#fff"
                className=""
                height={20}
                width={20}
              />
            )}
          Save changes
        </button>
      </div>
    </Layout>
  );
};

export default Availability;
