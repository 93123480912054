import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LeftPane from './expertAuthleftBg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';
import ReactLoading from 'react-loading';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { getEnvVars } from '../../app/env';

const { REACT_APP_API_URL } = getEnvVars();

const LoginExpt = () => {
  const navigate = useNavigate();
  const initialState = {
    email: '',
    password: '',
  };
  const toastID = Math.random();
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);

  const googleSuccess = async (credentialResponse) => {
    try {
      await axios
        .post(`${REACT_APP_API_URL}/v1/auth/signin-gauth`, {
          token: credentialResponse.credential,
        })
        .then((res) => {
          if (res.data.data.user.role !== 'expert') {
            toast.error('Sign in as a user', { toastId: toastID });
            return;
          }
          toast.success('Sign in successful', { toastId: toastID });
          localStorage.setItem('exptToken', res.data.data.token);
          localStorage.setItem('expertId', res.data.data.user.id);
          navigate('/expert/dashboard');
        });
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message, { toastId: toastID });
    }
  };

  const googleError = () => {
    toast.error('Google auth failed', { toastId: toastID });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios
        .post(`${REACT_APP_API_URL}/v1/auth/signin`, state)
        .then((res) => {
          if (res.data.data.user.role === 'client') {
            toast.info('Sign in as a user', { toastId: toastID });
            navigate('/login/user');
            setLoading(false);
            return;
          }
          localStorage.setItem('exptToken', res.data.data.token);
          localStorage.setItem('expertId', res.data.data.user.id);
          setLoading(false);
          toast.success(res.data.message, { toastId: toastID });
          navigate('/expert/dashboard');
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error(err.response.data.message, { toastId: toastID });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleView = () => {
    setView(!view);
  };

  return (
    <div className="grid lg:grid-cols-5 text-sm expert-auth mt-4 sm:mt-0">
      <div className="col-span-2 hidden lg:block">
        <LeftPane />
      </div>

      <div className="col-span-3 px-6 sm:px-36">
        <h1 className="font-semibold text-[28px] sm:mt-40 mt-28 mb-4">
          Welcome back valuable expert!
        </h1>

        <p>Let’s get you to your expert dashboard.</p>

        <form
          className="text-left mb-20 mt-10 flex-col flex justify-center"
          onSubmit={handleSubmit}
        >
          <div className="">
            <label htmlFor="email" className="font-medium">
              Email
            </label>

            <input
              type="email"
              className="w-full border border-neutral-300 rounded-lg p-2 mt-2"
              placeholder="Enter email"
              name="email"
              onChange={handleChange}
              value={state.email}
              required
            />
          </div>

          <div className="my-6">
            <label htmlFor="password" className="font-medium">
              Password
            </label>

            <div className="relative">
              <input
                type={view ? 'text' : 'password'}
                className="w-full border border-neutral-300 rounded-lg p-2 mt-2"
                placeholder="Enter Password"
                minLength={8}
                name="password"
                onChange={handleChange}
                value={state.password}
                required
              />
              {view ? (
                <FiEyeOff
                  className="absolute right-4 top-1/2 -m-0.5 cursor-pointer text-textLight"
                  onClick={handleView}
                />
              ) : (
                <FiEye
                  className="absolute right-4 top-1/2 -m-0.5 cursor-pointer text-textLight"
                  onClick={handleView}
                />
              )}
            </div>
          </div>

          <p className="text-neutral-700 text-center">
            Forgot password?{' '}
            <Link to="/forgot_password" className="text-purple-500 font-medium">
              Reset password
            </Link>
          </p>

          <button
            type="submit"
            className="bg-[#270058] flex align-middle items-center justify-center gap-2 text-white rounded p-2 mt-4 min-w-[12.5rem] m-auto"
          >
            {loading && (
              <ReactLoading
                type="cylon"
                color="#fff"
                className=""
                height={20}
                width={20}
              />
            )}

            Login
          </button>

          <div className="my-6 text-center">
            <div className="googleButton w-min mx-auto">
              <GoogleLogin onSuccess={googleSuccess} onError={googleError} />
            </div>
          </div>

          <p className="text-neutral-700 text-sm text-center flex gap-2 items-center justify-center">
            Don’t have an account?
            <Link to="/signup/expert" className="text-purple-500 font-medium">
              Create Account
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginExpt;
