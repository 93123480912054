import React, { useState, useEffect, useMemo } from 'react';
import Layout from '../../components/layout/accountLayout';
import { FiUpload, FiTrash2 } from 'react-icons/fi';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import {
  expertMedia,
  // getProfile,
  resetCalendar,
  resetMedia,
  updateProfile,
} from '../../features/experts/expertSlice';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { INDUSTRIES } from '../../data/constants';
import { BsClipboardCheck } from 'react-icons/bs';
import countryList from 'react-select-country-list';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Error from '../../components/shared/Error';
import { getEnvVars } from '../../app/env';
import SelectComponent from '../../components/shared/SelectComponent';

const { REACT_APP_API_URL, REACT_APP_FRONTEND_URL } = getEnvVars();

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    jobTitle: yup.string().required(),
    company: yup.string().required(),
    bio: yup.string().required(),
  })
  .required();

const Profile = () => {
  const dispatch = useDispatch();
  const { media, mediaStatus, expert } = useSelector(
    (state) => state.expert,
  );
  const initialState = {
    profilePhoto: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    company: '',
    industry: '',
    location: '',
    bio: '',
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState('');
  const [skillArr, setSkillArr] = useState([]);
  const [clipboard, setClipboard] = useState(false);
  const [referralClipboard, setReferralClipboard] = useState(false);
  const countriesList = useMemo(() => countryList().getData(), []);

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: state,
  });

  function handleFileInputChange(event) {
    event.preventDefault();
    dispatch(expertMedia({ photo: event.target.files[0] }));
  }

  useEffect(() => {
    if (expert) {
      setSkillArr(expert?.skills);
      setState({ ...state, ...expert });
      setValue('firstName', expert?.firstName);
      setValue('lastName', expert?.lastName);
      setValue('jobTitle', expert?.jobTitle);
      setValue('company', expert?.company);
      setValue('bio', expert?.bio);
      setValue('displayName', expert?.displayName);
      setValue('linkedinUrl', expert?.linkedinUrl);
      setValue('twitterUrl', expert?.twitterUrl);
    }
  }, [expert]);

  useEffect(() => {
    // dispatch(getProfile());
    if (mediaStatus === 'success') {
      const updateObj = {
        firstName: expert.firstName,
        lastName: expert.lastName,
        bio: expert.bio,
        location: expert.location,
        jobTitle: expert.jobTitle,
        company: expert.company,
        industry: expert.industry,
        skills: expert.skills,
        profilePhoto: expert.profilePhoto,
        fee: expert.fee,
        calendarUrl: expert.url,
      };
      for (const key in updateObj) {
        if (!/\S/.test(updateObj[key]) || !updateObj[key]) {
          delete updateObj[key];
        } else {
          updateObj[key] =
            typeof updateObj[key] === 'string'
              ? updateObj[key].trim()
              : updateObj[key];
        }
      }
      toast.success('profile image updated', { toastId: toastID });
      if (media) {
        dispatch(updateProfile({ ...updateObj, profilePhoto: media }));
      }
    }

    return () => {
      if (mediaStatus === 'success') {
        dispatch(resetMedia());
        dispatch(resetCalendar());
      }
    };
  }, [mediaStatus, loading]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      document.getElementById('btn').className = 'btn-default';
    }
  }, [dirtyFields]);

  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('exptToken'),
  };

  const toastID = Math.random();

  const handleFormSubmit = async (data) => {
    const resObj = {
      ...data,
      skills: skillArr,
      industry: state.industry,
      location: state.location,
      username: data.displayName,
    };

    delete resObj.profilePhoto;
    delete resObj.displayName;

    for (const key in resObj) {
      if (!/\S/.test(resObj[key]) || !resObj[key]) {
        delete resObj[key];
      } else {
        resObj[key] =
          typeof resObj[key] === 'string' ? resObj[key].trim() : resObj[key];
      }
    }

    try {
      setLoading(true);
      await axios
        .patch(`${REACT_APP_API_URL}/v1/experts/profile`, resObj, {
          headers,
        })
        .then((res) => {
          setLoading(false);
          toast.success(res.data.message, { toastId: toastID });
        });
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error(err.response.data.message, { toastId: toastID });
    }
  };

  const handleSkills = (e) => {
    setSkills(e.target.value);
  };

  const handleAddSkill = () => {
    if (skills === '' || skills === ' ') {
      return;
    }
    document.getElementById('btn').className = 'btn-default';
    setSkillArr([...skillArr, skills]);
    setSkills('');
  };

  const handleSkillDelete = (idx) => {
    document.getElementById('btn').className = 'btn-default';
    setSkillArr(skillArr.filter((item, num) => idx !== num));
  };

  const handleShareLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${REACT_APP_FRONTEND_URL}/user/${expert?.displayName}`,
      );
      setClipboard(true);
      setTimeout(() => {
        setClipboard(false);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  };

  const handleReferralLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${REACT_APP_FRONTEND_URL}?name=${expert?.displayName}`,
      );
      setReferralClipboard(true);
      setTimeout(() => {
        setReferralClipboard(false);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelect = (item, field) => {
    const previousValue = expert[field];
    const currentValue = item;
    if (previousValue !== currentValue.value) {
      setState({
        ...state,
        [field]: field === 'location' ? item.label : item.value,
      });
      document.getElementById('btn').className = 'btn-default';
    }
  };

  const handleFieldChange = (e, fieldName) => {
    const previousValue = expert[fieldName];
    const currentValue = e.target.value;
    if (previousValue !== currentValue) {
      document.getElementById('btn').className = 'btn-default';
    }
  };

  return (
    <ProtectedRoute>
      <Layout>
        <div className="shadow-sm bg-white p-5 sm:p-12 rounded-lg">
          <div className="flex sm:ml-4 justify-center sm:justify-start">
            <div className="w-16 h-16 object-cover relative">
              <img
                src={media ?? expert?.profilePhoto}
                alt=""
                className="rounded-lg w-full h-full"
              />
            </div>

            <div className="mx-6 flex gap-x-2 self-center">
              <input
                type="file"
                accept="image/*"
                className="opacity-0 w-[34px] -mr-[42px]"
                onChange={handleFileInputChange}
                required
              />

              <div className="rounded-lg border border-neutral-300 p-2 h-9 cursor-pointer">
                <FiUpload />
              </div>

              <div className="rounded-lg border border-neutral-300 p-2 h-9 cursor-pointer">
                <FiTrash2 />
              </div>
              {mediaStatus === 'loading' && (
                <ReactLoading
                  type="cylon"
                  color="#270058"
                  className=""
                  height={20}
                  width={20}
                />
              )}
            </div>
          </div>

          <hr className="my-4 max-w-[16rem] m-auto sm:max-w-full" />

          <form
            className="sm:grid sm:gap-y-5 flex flex-col gap-4 mt-10 w-full"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="grid sm:grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-0 relative">
              <div className="">
                <label htmlFor="firstName" className="font-medium text-sm">
                  First name
                </label>

                <input
                  type="text"
                  placeholder={expert?.firstName}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="firstName"
                  id="firstName"
                  {...register('firstName')}
                  onChange={(e) => handleFieldChange(e, 'firstName')}
                />
              </div>

              <div>
                <label htmlFor="lastName" className="font-medium text-sm">
                  Last name
                </label>

                <input
                  type="text"
                  placeholder={expert?.lastName}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="lastName"
                  id="lastName"
                  {...register('lastName')}
                  onChange={(e) => handleFieldChange(e, 'lastName')}
                />
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-0 relative">
              <div className="">
                <label htmlFor="" className="font-medium text-sm">
                  Email address
                </label>

                <input
                  type="email"
                  placeholder={expert?.email}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  disabled
                  onChange={(e) => handleFieldChange(e, 'email')}
                />
              </div>

              <div className="">
                <label htmlFor="displayName" className="font-medium text-sm">
                  Username
                </label>

                <input
                  type="text"
                  placeholder={
                    expert?.displayName
                      ? expert?.displayName
                      : 'Enter your username'
                  }
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="displayName"
                  id="displayName"
                  {...register('displayName')}
                />
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-0 relative">
              <div>
                <label htmlFor="linkedinUrl" className="font-medium text-sm">
                  LinkedIn URL
                </label>

                <input
                  type="url"
                  placeholder={expert?.linkedinUrl}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="linkedinUrl"
                  id="linkedinUrl"
                  {...register('linkedinUrl')}
                  onChange={(e) => handleFieldChange(e, 'linkedinUrl')}
                />

                <Error>
                  {errors.linkedinUrl && errors.linkedinUrl.message}
                </Error>
              </div>

              <div>
                <label htmlFor="twitterUrl" className="font-medium text-sm">
                  Twitter URL
                </label>

                <input
                  type="url"
                  placeholder={expert?.twitterUrl}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="twitterUrl"
                  id="twitterUrl"
                  {...register('twitterUrl')}
                  onChange={(e) => handleFieldChange(e, 'twitterUrl')}
                />
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-0 relative">
              <div>
                <label htmlFor="jobTitle" className="font-medium text-sm">
                  Job title
                </label>

                <input
                  type="text"
                  placeholder={expert?.jobTitle}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="jobTitle"
                  id="jobTitle"
                  {...register('jobTitle')}
                  onChange={(e) => handleFieldChange(e, 'jobTitle')}
                />
              </div>

              <div>
                <label htmlFor="company" className="font-medium text-sm">
                  Company
                </label>

                <input
                  type="text"
                  placeholder={expert?.company}
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                  name="company"
                  id="company"
                  {...register('company')}
                  onChange={(e) => handleFieldChange(e, 'company')}
                />
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-x-4 gap-y-4 sm:gap-y-0 relative">
              <SelectComponent
                options={INDUSTRIES}
                value={state.industry}
                onChange={(item) => handleSelect(item, "industry")}
                label={"Industry"}
                placeholder={"Select your industry"}
              />

              <SelectComponent
                options={countriesList}
                value={state.location}
                onChange={(item) => handleSelect(item, "location")}
                label={"Location"}
                placeholder={"Select your location"}
              />
            </div>

            <div className="relative">
              <label htmlFor="bio" className="font-medium text-sm">
                Bio
              </label>

              <textarea
                name="bio"
                id="bio"
                cols="30"
                rows="4"
                placeholder=""
                className="w-full p-2 border border-neutral-300 rounded-lg mt-1 text-sm sm:text-base"
                {...register('bio')}
                onChange={(e) => handleFieldChange(e, 'bio')}
              ></textarea>
            </div>

            <div className="mb-2">
              <p className="text-sm pb-4 font-medium">Skills</p>

              <div className="flex flex-wrap sm:gap-5 gap-3 sm:mb-6 mb-4">
                {skillArr?.map((skill, key) => (
                  <div
                    className="inline-flex items-center gap-4 justify-between text-sm sm:text-bases sm:py-2 py-1.5 sm:px-4 px-2.5 bg-white shadow-md rounded-md"
                    key={key}
                  >
                    {skill}
                    <IoMdClose
                      className="cursor-pointer"
                      onClick={() => handleSkillDelete(key)}
                    />
                  </div>
                ))}
              </div>

              <div className="flex sm:flex-row flex-col gap-2 sm:items-center">
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Enter skill one after the other"
                  className="w-full sm:p-2 p-1.5 text-sm sm:text-base border border-neutral-500 rounded-lg bg-transparent"
                  onChange={handleSkills}
                  value={skills}
                />

                <button
                  className="btn-default md:w-2/12 w-4/12 text-xs md:text-sm"
                  onClick={handleAddSkill}
                >
                  Add Skill
                </button>
              </div>
            </div>

            <button
              className="w-full btn-disabled flex items-center justify-center gap-2"
              id="btn"
              type="submit"
            >
              <div className="flex items-center justify-center gap-3 text-sm sm:text-base">
                {loading && (
                  <ReactLoading
                    type="cylon"
                    color="#fff"
                    className=""
                    height={20}
                    width={20}
                  />
                )}
                Save Changes
              </div>
            </button>
          </form>
        </div>

        <div className="sm:mt-8 mt-5">
          <p className="font-semibold sm:text-xl text-lg py-4">Profile Link</p>

          <div className="flex sm:flex-row flex-col gap-2 sm:items-center">
            <input
              type="text"
              name=""
              id=""
              disabled
              placeholder={
                expert?.displayName
                  ? `${REACT_APP_FRONTEND_URL}/user/${expert?.displayName}`
                  : 'No profile link available set your username'
              }
              className="w-full sm:p-2 p-1.5 text-sm sm:text-base text-textLight border border-neutral-500 rounded-lg bg-transparent"
              value={
                expert?.displayName
                  ? `${REACT_APP_FRONTEND_URL}/user/${expert?.displayName}`
                  : 'No profile link available set your username'
              }
            />

            <button
              className="btn-default md:w-2/12 w-4/12 text-xs md:text-sm relative"
              onClick={handleShareLink}
            >
              {clipboard && (
                <div className="absolute shadow-md rounded-md -top-10 text-black bg-white py-1 px-3 flex gap-2 items-center">
                  <BsClipboardCheck />
                  copied
                </div>
              )}
              Copy Link
            </button>
          </div>
          {!expert?.displayName && (
            <p className="text-sm text-red-500 mt-2">
              Your username is required to have a profile link
            </p>
          )}

          <p className="font-semibold sm:text-xl text-lg py-4">Referral Link</p>

          <div className="flex sm:flex-row flex-col gap-2 sm:items-center">
            <input
              type="text"
              name=""
              id=""
              disabled
              placeholder={
                expert?.displayName
                  ? `${REACT_APP_FRONTEND_URL}?name=${expert?.displayName}`
                  : 'No referral link available until you set your username'
              }
              className="w-full sm:p-2 p-1.5 text-sm sm:text-base placeholder:text-sm text-textLight border border-neutral-500 rounded-lg bg-transparent"
              value={
                expert?.displayName
                  ? `${REACT_APP_FRONTEND_URL}?name=${expert?.displayName}`
                  : ''
              }
            />

            <button
              className="btn-default md:w-2/12 w-4/12 text-xs md:text-sm relative"
              onClick={handleReferralLink}
            >
              {referralClipboard && (
                <div className="absolute shadow-md rounded-md -top-10 text-black bg-white py-1 px-3 flex gap-2 items-center">
                  <BsClipboardCheck />
                  copied
                </div>
              )}
              Copy Link
            </button>
          </div>

          {!expert?.displayName && (
            <p className="text-xs text-red-500 mt-2">
              Your username is required to have a referral link
            </p>
          )}
        </div>
      </Layout>
    </ProtectedRoute>
  );
};

export default Profile;
