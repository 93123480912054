import React from 'react';

const Container = ({ children, extraClass }) => {
  return (
    <div
      className={`w-full max-w-[1600px] mx-auto px-[1rem] sm:px-[2.5rem] ${extraClass ? extraClass : ''
        } `}
    >
      {children}
    </div>
  );
};

export default Container;
