import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { FaDesktop } from 'react-icons/fa';
import { BiBriefcase, BiChevronDown } from 'react-icons/bi';
import { IoClose } from 'react-icons/io5';
import fitness from '../assets/icons/fitness.svg';
import pbrush from '../assets/icons/homedecor.svg';
import marketing from '../assets/icons/marketing.svg';
import beauty from '../assets/icons/style.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, searchText } from '../features/user/userSlice';
import Experts from '../components/expert/expertsCarousel';
import { getGiftSession, resetSession } from '../features/session/sessionSlice';
import Layout from '../components/layout/layout';
import SelectMenu from '../components/shared/SelectMenu';
import { searchOption } from '../data/select';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [userId /*setUserId*/] = useState(() => localStorage.userId);
  const [showModal, setShowModal] = useState(true);
  const [filteredValue, setFilteredValue] = useState([]);
  const [searchParams, setSearchParams] = useState('');

  const { giftSessions } = useSelector((state) => state.session);

  useEffect(() => {
    dispatch(getUser({ id: userId }));
  }, [userId]);

  useEffect(() => {
    dispatch(getGiftSession());
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search.trim()) {
      dispatch(searchText(search));
      navigate(`/search/results?search=${searchParams}`);
    }
  };

  useEffect(() => {
    setFilteredValue(
      giftSessions?.filter(
        (item) =>
          item?.recipient?.id === localStorage.getItem('userId') &&
          item?.isClaimed === false,
      ),
    );
  }, [giftSessions]);

  const claimGift = () => {
    dispatch(resetSession());
    navigate(`/gift-book/${filteredValue[0]?.expert?.id}`);
  };

  const handleFilterFields = (item) => {
    setSearchParams(item.option);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  return (
    <div className="search mx-auto altNav">
      <FacebookPixel />
      <Layout>
        {showModal && filteredValue?.length > 0 && (
          <div>
            {filteredValue?.length !== 0 ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-6 md:mx-auto max-w-md md:max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-10 py-8 lg:px-40 lg:py-12">
                      <IoClose
                        onClick={() => setShowModal(false)}
                        className="cursor-pointer ml-auto lg:-mr-20 mb-4"
                        size={20}
                      />
                      <div className="flex p-5 rounded-t">
                        <h3 className="text-3xl font-semibold text-center mx-auto">
                          Welcome to Zedintro
                        </h3>
                      </div>
                      {filteredValue?.length > 0 ? (
                        <p className="text-center">
                          {filteredValue[0]?.gifter?.firstName +
                            ' ' +
                            filteredValue[0]?.gifter?.lastName}{' '}
                          gifted you a call with{' '}
                          {filteredValue[0]?.expert?.firstName +
                            ' ' +
                            filteredValue[0]?.expert?.lastName}
                          . You can decide when you want your call by scheduling
                          a call with{' '}
                          {filteredValue[0]?.expert?.firstName +
                            ' ' +
                            filteredValue[0]?.expert?.lastName}
                          .
                        </p>
                      ) : null}
                      <button
                        className="btn-default w-64 mx-auto mt-20"
                        onClick={claimGift}
                      >
                        Book Call
                      </button>
                    </div>
                  </div>
                </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </div>
        )}

        <section className="one drop-shadow-md py-24 bg-[#41008B]">
          <div className="text-center text-white px-8">
            <h1 className="sm:text-4xl text-3xl font-semibold pb-2">
              Search for Experts
            </h1>

            <p className="text-sm sm:text-base">
              Explore from our collection of industry leaders, and talk to them
              with ease.
            </p>
          </div>

          <form
            className="text-center mx-auto px-4 sm:px-0 w-full sm:w-10/12 mt-4 relative"
            onSubmit={handleSearch}
          >
            <div className="sm:rounded-xl rounded-lg max-w-5xl w-full m-auto flex bg-white shadow-md relative">
              <div className="bg-[#EDDBFF]/70 sm:rounded-l-xl rounded-l-lg flex items-center">
                <SelectMenu
                  options={searchOption}
                  className={'top-12 min-w-[10rem]'}
                  optionField="searchField"
                  handleSelect={handleFilterFields}
                >
                  <div className="space-x-2 px-4 text-sm flex justify-center items-center text-bgPurple">
                    <p>{searchParams === 'skill' ? 'Skill' : 'Name'}</p>
                    <BiChevronDown className="text-xl" />
                  </div>
                </SelectMenu>
              </div>

              <input
                type="text"
                name="industry"
                placeholder={
                  searchParams === 'skill'
                    ? 'example: marketing, architecture'
                    : 'example: Johnson, Anna'
                }
                className="pl-4 bg-white py-2 sm:py-3 lg:py-4 grow outline-none border-none placeholder:text-sm sm:rounded-r-xl rounded-r-lg"
                value={search}
                onChange={handleChange}
              />

              <button
                className="flex items-center justify-center pr-5 text-right sm:rounded-r-xl rounded-r-lg absolute right-0 h-full"
                onClick={handleSearch}
              >
                <FiSearch className="text-gray-500 lg:text-xl sm:text-lg text-base" />
              </button>
            </div>
          </form>
        </section>

        <section className="two py-20  overflow-hidden">
          <Container>
            <h2 className="sm:text-4xl text-3xl text-center font-semibold leading-10 sm:pt-10 pt-2 pb-8">
              Trending Experts
            </h2>
            <Experts />
          </Container>
        </section>

        <section className="sm:py-20 py-2">
          <Container>
            <h2 className="font-semibold sm:text-4xl text-3xl pb-6">
              Popular Categories
            </h2>
            <div className="flex flex-row flex-wrap gap-4 sm:gap-6">
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <FaDesktop className="text-purple-600 self-center mr-2" />
                <p>Front-End</p>
              </div>
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <img
                  src={beauty}
                  alt="beauty"
                  className="text-purple-600 self-center mr-2 sm:w-5 w-3"
                />
                <p>Style & Beauty</p>
              </div>
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <img
                  src={fitness}
                  alt="fitness"
                  className="text-purple-600 self-center mr-2 sm:w-5 w-3"
                />
                <p>Fitness</p>
              </div>
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <FaDesktop className="text-purple-600 self-center mr-2" />
                <p>Style & Beauty</p>
              </div>
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <img
                  src={pbrush}
                  alt="brush"
                  className="text-purple-600 self-center mr-2 sm:w-5 w-3"
                />
                <p>Home Decor</p>
              </div>
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <BiBriefcase className="text-purple-600 self-center mr-2" />
                <p>Career & Business</p>
              </div>
              <div className="shadow-md rounded-lg flex items-center w-max sm:p-4 p-2 text-xs sm:text-base bg-white">
                <img
                  src={marketing}
                  alt="marketing"
                  className="text-purple-600 self-center mr-2 sm:w-5 w-3"
                />
                <p>Marketing</p>
              </div>
            </div>
          </Container>
        </section>
      </Layout>
    </div>
  );
};

export default Search;
