import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import confirm from '../../assets/icons/confirm.svg';

const GoogleSuccess = () => {
  const location = useLocation();

  return (
    <div className="confirmation mx-auto h-screen grid">
      <div className="text-center self-center mx-8">
        <img src={confirm} className="mx-auto" alt="confirm-icon" />

        <h1 className="font-bold text-3xl leading-9 my-8">
          Thank you for signing up!
        </h1>

        <p className="px-4 md:px-44 lg:px-80 xl:px-96">
          Please click the button below to proceed to the sign in page.
        </p>

        {location.pathname.includes('expert') ? (
          <Link to="/login/expert">
            <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-3 px-5">
              Done
            </button>
          </Link>
        ) : (
          <Link to="/login/user">
            <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-3 px-5">
              Done
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default GoogleSuccess;
