import React, { useEffect, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import Error from '../../shared/Error';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const StepOneForm = ({ handleStepOneData, formData }) => {
  const navigate = useNavigate();
  const [hasAgreedToPolicy, setHasAgreedToPolicy] = useState(false);
  const [view, setView] = useState({
    password: false,
    confirmPassword: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue('firstName', formData.firstName);
    setValue('lastName', formData.lastName);
    setValue('email', formData.email);
  }, [formData]);

  const handleView = (idx) => {
    if (idx === 1) {
      setView({ ...view, password: !view.password });
    } else {
      setView({ ...view, confirmPassword: !view.confirmPassword });
    }
  };

  const handleFormCheck = (e) => {
    setHasAgreedToPolicy(e.target.checked);
  };

  const handleStepOneForm = (data) => {
    for (const key in data) {
      data[key] = data[key].trim();
    }

    if (!hasAgreedToPolicy) {
      alert('Please agree to the terms & privacy policy to continue');
      return;
    }

    handleStepOneData(data);
  };

  return (
    <div className="">
      <div className="flex flex-wrap md:flex-nowrap justify-center items-center mt-16 mb-4 sm:mb-12">
        <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Get Started
        </button>
        <button className="border-b-2 border-textLight text-textLight py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Complete Setup
        </button>
        <button className="border-b-2 border-textLight text-textLight py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Email Verification
        </button>
        <button className="border-b-2 border-textLight text-textLight py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Email Confirmation
        </button>
      </div>

      <h1 className="font-semibold md:text-3xl text-xl">
        Let’s Create Your Expert Account
      </h1>

      <form
        className="text-left sm:mt-10 mt-4 sm:my-20 my-10 form1 grid gap-y-5"
        onSubmit={handleSubmit(handleStepOneForm)}
      >
        <div className="flex flex-col gap-2 relative">
          <label htmlFor="name" className="font-medium">
            First Name
          </label>

          <input
            type="text"
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="First Name"
            name="firstName"
            {...register('firstName', {
              required: 'Required',
            })}
          />

          <Error>{errors?.firstName && errors.firstName.message}</Error>
        </div>

        <div className="flex flex-col gap-2 relative">
          <label htmlFor="lastname" className="font-medium">
            Last Name
          </label>

          <div className="">
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Last Name"
              name="lastName"
              {...register('lastName', {
                required: 'Required',
              })}
            />
          </div>

          <Error>{errors.lastName && errors.lastName.message}</Error>
        </div>

        <div className="flex flex-col gap-2 relative">
          <label htmlFor="email" className="font-medium">
            Email
          </label>

          <input
            type="email"
            className="w-full border border-gray-300 rounded-md p-2"
            placeholder="Enter email"
            name="email"
            {...register('email', {
              required: 'Required',
            })}
          />

          <Error>{errors.email && errors.email.message}</Error>
        </div>

        <div className="flex flex-col gap-2 relative">
          <label htmlFor="password" className="font-medium">
            Password
          </label>

          <div className="relative">
            <input
              type={view.password ? 'text' : 'password'}
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Password"
              minLength={8}
              name="password"
              {...register('password', {
                required: 'Required',
              })}
            />

            {view.password ? (
              <FiEyeOff
                className="absolute right-4 top-1/3 cursor-pointer text-textLight"
                onClick={() => handleView(1)}
              />
            ) : (
              <FiEye
                className="absolute right-4 top-1/3 cursor-pointer text-textLight"
                onClick={() => handleView(1)}
              />
            )}
          </div>

          <Error>{errors.password && errors.password.message}</Error>
        </div>

        <div className="flex gap-2">
          <input
            type="checkbox"
            name="formCheck"
            id="formCheck"
            onChange={handleFormCheck}
          />

          <label
            htmlFor="formCheck"
            className="text-neutral-700 text-xs font-medium"
          >
            By signing up, I agree to Zedintro’s
            <span
              className="text-purple-700 cursor-pointer font-bold"
              onClick={() => navigate('/terms')}
            >
              {' '}
              Terms & Conditions{' '}
            </span>
            and
            <span
              className="text-purple-700 cursor-pointer font-bold"
              onClick={() => navigate('/privacy')}
            >
              {' '}
              Privacy Policy{' '}
            </span>
          </label>
        </div>

        <button
          type="submit"
          className="w-full bg-[#270058] text-white rounded p-2 font-semibold"
        >
          Proceed
        </button>

        <p className="text-neutral-700 text-center flex gap-2 items-center justify-center mt-4">
          Already have an account?
          <Link to="/login/expert/" className="text-purple-500 font-medium">
            Sign in
          </Link>
        </p>
      </form>
    </div>
  );
};

export default StepOneForm;
