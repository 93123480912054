export const teamData = [
  {
    id: 1,
    name: 'IZED UANIKHEHI',
    twitter: '',
    linkedin: '',
    mail: '',
  },
  {
    id: 2,
    name: 'ELIZABETH UGHORO',
    twitter: '',
    linkedin: '',
    mail: '',
  },
  {
    id: 3,
    name: 'KEMI OGUNMUYIWA',
    twitter: 'Adam A.',
    linkedin: '',
    mail: '',
  },
];

export const zedIntroDifference = [
  {
    id: 1,
    header: 'Tailored to Your Schedule',
    text: 'Our flexible scheduling is designed to accommodate your busy schedule. Book sessions at your convenience, and make the most out of your time with industry experts.',
  },
  {
    id: 2,
    header: 'Confidential and Secure',
    text: 'At ZedIntro, we prioritize your privacy and security. All interactions and conversations are kept strictly confidential, ensuring a safe environment for meaningful discussions.',
  },
  {
    id: 3,
    header: 'Transparent',
    text: 'We believe in fair and transparent practices to ensure your satisfaction and peace of mind. We will refund the amount you paid if there is an emergency for you or our expert.',
  },
  {
    id: 4,
    header: 'Tailored to Your Schedule',
    text: 'Our flexible scheduling is designed to accommodate your busy schedule. Book sessions at your convenience, and make the most out of your time with industry experts.',
  },
];

export const reasonData = [
  {
    id: 1,
    header: 'Unmatched Expertise',
    text: 'Our experts are carefully selected based on their proven track record, industry recognition, and ability to deliver valuable insights. When you engage with them through ZedIntro, you can be confident that you&apos;re learning from the best.'
  },
  {
    id: 2,
    header: 'Tailored to Your Schedule',
    text: 'Our flexible scheduling is designed to accommodate your busy schedule. Book sessions at your convenience, and make the most out of your time with industry experts.'
  },
  {
    id: 3,
    header: 'Confidential and Secure',
    text: 'At ZedIntro, we prioritize your privacy and security. All interactions and conversations are kept strictly confidential, ensuring a safe environment for meaningful discussions.'
  },
  {
    id: 4,
    header: 'Transparent',
    text: 'We believe in fair and transparent practices to ensure your satisfaction and peace of mind. We will refund the amount you paid if there is an emergency for you or our expert.'
  },
  {
    id: 5,
    header: 'Customized Choices',
    text: 'Our services range from consultations to mentoring to training, depending on the specific needs of your career or business.'
  },
]
