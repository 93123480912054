import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LeftPane from './authleftBg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import ReactLoading from 'react-loading';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { getEnvVars } from '../../app/env';
import FacebookPixel from '../../utils/FacebookPixel';

const { REACT_APP_FRONTEND_URL, REACT_APP_API_URL } = getEnvVars();

const signupUser = () => {
  const navigate = useNavigate();

  const [screen, setScreen] = useState(false);
  const [userName, setUserName] = useState("");
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    homepageUrl: REACT_APP_FRONTEND_URL,
    verificationSuccessfulUrl: `https://app.zedintro.com/email_verification_success?redirect_to=${window.sessionStorage.getItem('path') || ''
      }`,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState({
    password: false,
    confirmPassword: false,
  });
  const toastID = Math.random();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const nameParam = params.get('name');
    setUserName(nameParam ?? "");
  }, []);

  const googleSuccess = async (credentialResponse) => {
    let reqData = {
      token: credentialResponse.credential,
      homepageUrl: REACT_APP_FRONTEND_URL,
      verificationSuccessfulUrl: initialState.verificationSuccessfulUrl,
    };
    if (userName) {
      reqData = { ...reqData, referralCode: userName };
    }

    try {
      await axios
        .post(`${REACT_APP_API_URL}/v1/auth/register-client-gauth`, reqData)
        .then((res) => {
          toast.success('Sign up success', { toastId: toastID });
          localStorage.setItem('userToken', res.data.data.token);
          navigate(
            `/signup_success?redirect_to=${
            // we probably don't need this redirect_to here since the user will be clicking from their mail
            window.sessionStorage.getItem('path') || ''
            }`,
          );
        });
      sessionStorage.removeItem('referralId');
      navigate(
        `/signup_success?redirect_to=${window.sessionStorage.getItem('path') || ''
        }`,
      );
    } catch (err) {
      console.error(err);
      toast.error('Authentication failed', { toastId: toastID });
    }
  };

  const googleError = () => {
    toast.error('Google auth failed', { toastId: toastID });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let reqData = { ...state };
    if (userName) {
      reqData = { ...reqData, referralCode: userName };
    }

    try {
      setLoading(true);
      await axios
        .post(`${REACT_APP_API_URL}/v1/auth/register-client`, reqData)
        .then((res) => {
          toast.success(res.data.message, { toastId: toastID });
          setLoading(false);
          sessionStorage.removeItem('referralId');
          navigate('/signup_success');
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.response.data.message, { toastId: toastID });
    }
  };

  const handleView = (idx) => {
    if (idx === 1) {
      setView({ ...view, password: !view.password });
    } else {
      setView({ ...view, confirmPassword: !view.confirmPassword });
    }
  };

  return (
    <>
      <FacebookPixel />
      <div className="grid lg:grid-cols-5 text-sm expert-auth">
        <div className="col-span-2 hidden lg:block">
          <LeftPane />
        </div>

        <div className="col-span-3 px-6 sm:px-36 flex flex-col justify-center items-center h-screen">
          {screen !== false ? (
            <div className="py-10">
              <div className="flex md:flex-wrap justify-center items-center mt-4 mb-4 sm:mb-10">
                <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
                  Get Started
                </button>
                <button className="border-b-2 border-textLight text-textLight py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
                  Email Verification
                </button>
                <button className="border-b-2 border-textLight text-textLight py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
                  Email Confirmation
                </button>
              </div>

              <h1 className="font-semibold text-2xl md:text-3xl">
                Create an account on ZedIntro
              </h1>

              <form
                action=""
                method="post"
                className="text-left sm:mt-10 mt-4 grid gap-y-5 max-w-[35rem]"
                onSubmit={handleSubmit}
              >
                <div>
                  <label htmlFor="firstName" className="font-medium">
                    First Name
                  </label>

                  <input
                    type="text"
                    id="firstName"
                    className="w-full border border-gray-300 rounded-md p-2 px-4 mt-2"
                    placeholder="First Name"
                    name="firstName"
                    value={state.firstName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="name" className="font-medium">
                    Last Name
                  </label>

                  <input
                    type="text"
                    className="w-full border border-gray-300 rounded-md p-2 px-4 mt-2"
                    placeholder="Last Name"
                    name="lastName"
                    value={state.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="email" className="font-medium">
                    Email
                  </label>

                  <input
                    type="email"
                    className="w-full border border-gray-300 rounded-md p-2 px-4 mt-2"
                    placeholder="Enter email"
                    name="email"
                    value={state.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="password" className="font-medium">
                    Password
                  </label>

                  <div className="relative">
                    <input
                      type={view.password ? 'text' : 'password'}
                      className="w-full border border-gray-300 rounded-md p-2 px-4 mt-2"
                      placeholder="Password"
                      minLength={8}
                      name="password"
                      value={state.password}
                      onChange={handleChange}
                      required
                    />

                    {view.password ? (
                      <FiEyeOff
                        className="absolute right-4 top-1/2 -mt-0.5 cursor-pointer text-textLight"
                        onClick={() => handleView(1)}
                      />
                    ) : (
                      <FiEye
                        className="absolute right-4 top-1/2 -mt-0.5 cursor-pointer text-textLight"
                        onClick={() => handleView(1)}
                      />
                    )}
                  </div>
                </div>

                <div className="flex sm:items-center gap-2">
                  <input type="checkbox" name="" id="" />
                  <label className="text-neutral-700 flex flex-wrap text-xs sm:text-sm space-x-1.5 items-center">
                    By signing up, I agree to ZedIntro
                    <Link
                      to="/terms"
                      className="text-bgPurple font-bold mx-1.5"
                    >
                      Terms & Conditions
                    </Link>
                    and
                    <Link to="/privacy" className="text-bgPurple font-bold">
                      Privacy Policy
                    </Link>
                  </label>
                </div>

                <button
                  className="w-full bg-[#270058] text-white rounded p-2 mt-2 sm:mt-6 flex justify-center item gap-4"
                  type="submit"
                >
                  {loading && (
                    <ReactLoading
                      type="cylon"
                      color="#fff"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                  Create account
                </button>

                <p className="text-neutral-700 text-center flex gap-2 items-center justify-center mt-4">
                  Already have an account?
                  <Link to="/login/user/" className="text-purple-500">
                    Sign in
                  </Link>
                </p>
              </form>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-neutral-700 text-center flex gap-2 items-center justify-center mb-6">
                Not a client?
                <Link to="/signup/expert" className="text-purple-500 font-medium">
                  Sign up as an expert?
                </Link>
              </p>

              <h1 className="font-semibold text-center text-[28px] mb-4">
                Let’s Get Started
              </h1>

              <p>Create an account on Zedintro</p>

              <button
                className="bg-[#270058] text-white min-w-[12.5rem] rounded p-2 mt-6"
                type="button"
                onClick={() => setScreen(true)}
              >
                Continue with email
              </button>

              <div className="text-sm text-gray-500 text-center my-4">OR</div>

              <div className="googleButton w-min mx-auto">
                <GoogleLogin
                  onSuccess={googleSuccess}
                  onError={googleError}
                  text="signup_with"
                />
              </div>

              <p className="text-neutral-700 text-center flex gap-2 items-center justify-center mt-6">
                Already have an account?
                <Link to="/login/user/" className="text-purple-500 font-medium">
                  Login
                </Link>
              </p>

              <p className="text-neutral-700 text-center flex flex-wrap gap-2 items-center justify-center mt-16">
                By signing up I agree to ZedIntro
                <Link to="/terms" className="text-bgPurple font-bold">
                  Terms & Conditions
                </Link>
                and
                <Link to="/privacy" className="text-bgPurple font-bold">
                  Privacy Policy
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default signupUser;
