import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FiUser, FiChevronDown } from 'react-icons/fi';
import DropDown from '../shared/DropDown';
import { BiMenu } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import useExternalClick from '../../hooks/useExternalClick';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../features/experts/expertSlice';
import { EXPERT_TOKEN_KEY } from '../../data/constants';
import { setDashGuide } from '../../features/app/appSlice';
import Container from '../shared/Container';
// import ExpertMenu from "./shared/ExpertMenu";

const options = [
  {
    id: 1,
    value: 'Profile',
  },
  {
    id: 3,
    value: 'Logout',
  },
];

const dashNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { expert } = useSelector((state) => state.expert);
  const { dashGuide } = useSelector((state) => state.app);
  const [showModal, setShowModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerView, setHeaderView] = useState('top');

  const ref = useRef();

  useEffect(() => {
    if (!expert) {
      dispatch(getProfile());
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
      if (currentPosition < 76 && currentPosition < scrollPosition) {
        setHeaderView('top');
      } else if (currentPosition > scrollPosition || currentPosition < 56) {
        setHeaderView('down');
      } else {
        setHeaderView('up');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const handleOnClick = () => {
    if (!showModal) {
      return;
    } else {
      setShowModal(false);
    }
  };

  useExternalClick(ref, handleOnClick);

  function home() {
    if (localStorage.getItem(EXPERT_TOKEN_KEY)) {
      navigate('/expert/dashboard');
    } else {
      navigate('/login/expert');
    }
  }

  const handleSelect = (idx) => {
    if (idx === 1) {
      navigate('/expert/account/profile');
    } else if (idx === 3) {
      localStorage.removeItem('exptToken');
      navigate('/login/expert');
      window.location.reload();
    }
  };

  const removeGuide = () => {
    dispatch(setDashGuide(null));
    localStorage.setItem('expertGuide', null);
  };

  const handleNextGuide = (idx) => {
    dispatch(setDashGuide(idx));
    localStorage.setItem('expertGuide', idx);
  };

  return (
    <nav
      className={`bg-[#1D1A22] mx-auto w-full transition-all duration-500 ${
        headerView ? '' : '-translate-y-full'
      }`}
    >
      <Container>
        <div className="flex relative items-center h-24 sm:h-16 z-30">
          <div className="sm:basis-1/3">
            <button
              className="text-white text-3xl font-bold block w-auto"
              onClick={home}
            >
              Zedintro
            </button>
          </div>

          <div className="sm:flex -ml-2 sm:ml-0 sm:basis-1/3 hidden justify-center">
            <div className="relative flex items-center">
              <NavLink
                to="/expert/dashboard"
                className="text-white hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium ml-auto"
              >
                Dashboard
              </NavLink>
            </div>

            <div className="relative flex items-center">
              <NavLink
                to="/expert/Call"
                className="text-white px-3 py-2 hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white rounded-md text-sm font-medium"
              >
                Calls
              </NavLink>

              {dashGuide === 8 && (
                <div className="absolute top-12 left-0 w-full">
                  <div className="bg-white p-6 w-[20rem] md:w-[23rem] lg:w-[28rem] rounded-lg lg:rounded-2xl">
                    <h2 className="font-semibold text-2xl">
                      See all past and recent calls here
                    </h2>

                    <p className="my-3">
                      View all approved, booked, declined and done calls here.
                    </p>

                    <div className="flex align-middle space-x-4 items-center mt-8">
                      <button
                        className="text-[#792AE2] font-semibold mr-auto"
                        onClick={() => handleNextGuide(7)}
                      >
                        Previous
                      </button>

                      <button
                        className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                        onClick={() => handleNextGuide(9)}
                      >
                        Next
                      </button>

                      <button
                        className="text-[#792AE2] font-semibold"
                        onClick={removeGuide}
                      >
                        Skip
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="relative flex items-center">
              <NavLink
                to="/expert/account/profile"
                className="text-white hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium mr-auto"
              >
                Account
              </NavLink>

              {dashGuide === 9 && (
                <div className="absolute top-12 left-0 w-full">
                  <div className="bg-white p-6 w-[18rem] md:w-[20rem] lg:w-[28rem] rounded-2xl ">
                    <h2 className="font-semibold text-2xl">
                      Setup your account here
                    </h2>

                    <p className="my-3">View all your account details here</p>

                    <div className="flex align-middle space-x-4 items-center mt-8">
                      <button
                        className="text-[#792AE2] font-semibold mr-auto"
                        onClick={() => handleNextGuide(8)}
                      >
                        Previous
                      </button>

                      <button
                        className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                        onClick={removeGuide}
                      >
                        Next
                      </button>

                      <button
                        className="text-[#792AE2] font-semibold"
                        onClick={removeGuide}
                      >
                        Skip
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="ml-auto sm:border-0 sm:inline-block">
            <DropDown
              options={options}
              handleSelect={handleSelect}
              className={'ml-auto w-36 left-[-5rem] top-14'}
            >
              <div className="flex ml-auto">
                <div className="bg-white rounded-full p-0.5">
                  {expert?.profilePhoto ? (
                    <div className="w-6 h-6 rounded-full object-cover">
                      <img
                        src={expert?.profilePhoto}
                        alt=""
                        className="w-full h-full rounded-full"
                      />
                    </div>
                  ) : (
                    <FiUser className="text-[#270058]" size={18} />
                  )}
                </div>
                <FiChevronDown className="text-[#C197FF] self-center" />
              </div>
            </DropDown>
          </div>

          <div className="sm:ml-auto ml-2 sm:hidden">
            {showModal ? null : (
              <BiMenu
                className="text-white text-4xl cursor-pointer"
                onClick={() => setShowModal(true)}
              />
            )}
          </div>
          {dashGuide === 9 && (
            <div className="absolute sm:hidden top-28 left-0 w-full">
              <div className="bg-white p-6 sm:w-[28rem] rounded-2xl">
                <h2 className="font-semibold text-2xl">
                  Setup your account here
                </h2>

                <p className="my-3">View all your account details here</p>

                <div className="flex align-middle space-x-4 items-center mt-8">
                  <button
                    className="text-[#792AE2] font-semibold mr-auto"
                    onClick={() => handleNextGuide(8)}
                  >
                    Previous
                  </button>

                  <button
                    className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                    onClick={removeGuide}
                  >
                    Next
                  </button>

                  <button
                    className="text-[#792AE2] font-semibold"
                    onClick={removeGuide}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          )}

          {dashGuide === 8 && (
            <div className="absolute sm:hidden top-28 left-0 w-full">
              <div className="bg-white p-6 lg:w-[28rem] rounded-2xl">
                <h2 className="font-semibold text-2xl">
                  See all past and recent calls here
                </h2>

                <p className="my-3">
                  View all approved, booked, declined and done calls here.
                </p>

                <div className="flex align-middle space-x-4 items-center mt-8">
                  <button
                    className="text-[#792AE2] font-semibold mr-auto"
                    onClick={() => handleNextGuide(7)}
                  >
                    Previous
                  </button>

                  <button
                    className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                    onClick={() => handleNextGuide(9)}
                  >
                    Next
                  </button>

                  <button
                    className="text-[#792AE2] font-semibold"
                    onClick={removeGuide}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={`top-0 left-0 w-screen h-screen z-30 backdrop-blur-sm transition-all duration-500 ${
            showModal ? 'fixed' : 'hidden'
          }`}
        >
          <div
            className={`bg-white relative z-30 pt-8 pb-12 px-12 max-w-md m-auto mt-32 border rounded-md shadow-md transition-all duration-1000 ${
              showModal
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 translate-y-24'
            }`}
            ref={ref}
          >
            <MdClose
              className="text-3xl ml-auto cursor-pointer"
              onClick={() => setShowModal(false)}
            />
            <ul className="flex flex-col items-end justify-center align-middle gap-10 font-bold text-lg mt-10 text-textPurple">
              <li>
                <Link to={'/expert/dashboard'}>Dashboard</Link>
              </li>
              <li>
                <Link to={'/expert/call'}>Calls</Link>
              </li>
              <li>
                <Link to={'/expert/account/profile'}>Account</Link>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default dashNavbar;
