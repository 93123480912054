import { z, parseEnv } from 'znv';

export const getEnvVars = () => {
  return parseEnv(process.env, {
    REACT_APP_API_URL: z.string(),
    REACT_APP_GOOGLE_AUTH_CLIENT_ID: z.string(),
    REACT_APP_FRONTEND_URL: z.string(),
    REACT_APP_FLW_PUBLIC_KEY: z.string(),
    REACT_APP_FF_AD_IMAGES: z.enum(["true", "false"]),
    REACT_APP_SENTRY_DSN: z.string(),
    REACT_APP_PAYMENT_APP_ID: z.string(),
    REACT_APP_SENTRY_ENV: z.enum(["localhost", "staging", "production"]),
    REACT_APP_CLOUDINARY_CLOUD_NAME: z.string(),
    REACT_APP_CLOUDINARY_UPLOAD_PRESET: z.string(),
    REACT_APP_APP_BASE_URL: z.string(),
    REACT_APP_IS_LOCALHOST_FLAG: z.enum(["true", "false"]),
  });
};
