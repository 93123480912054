import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay } from 'swiper';
import { reasonData } from '../data/team';

export default function ReasonCarousel() {
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={60}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
        className="mySwiper flex justify-between w-full gap-10"
      >
        {reasonData.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="sm:max-w-[30rem] max-w-[23rem] flex justify-between w-full rounded-xl shadow-md sm:h-52 h-56">
              <div className="p-5 categories">
                <h4 className=" font-bold text-xl mb-2">{item.header}</h4>
                <p className="text-gray-500">{item.text}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
