import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

const placeholderDefaultStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
};

const customSelectStyle = {
    option: (provided, { isDisabled, isSelected, isFocused }) => ({
        ...provided,
        fontSize: "14px",
        fontWeight: 400,
        backgroundColor: isFocused ? "#D6E4F4" : isSelected ? "#270058" : undefined,
        lineHeight: "16px",
        cursor: isDisabled ? "not-allowed" : "pointer",
        "&:active": {
            backgroundColor: isSelected ? "#270058" : "#EFEFEF"
        },
        "&:hover": {
            backgroundColor: isSelected ? "#270058" : "#EFEFEF"
        }
    }),
    control: (base) => ({
        ...base,
        paddingRight: "6px",
        border: "1px solid #d1d5db",
        borderRadius: "6px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "28px",
        boxShadow: "none",
        backgroundColor: "white",
        color: "#000",
        cursor: "pointer",
        "&:focus-within": {
            border: "2px solid blue"
        }
    }),
    menu: (provided) => ({
        ...provided,
        border: "1px solid #ffffff",
        borderRadius: "4px",
        filter: "drop-shadow(4px 4px 16px rgba(0, 0, 0, 0.1))",
        zIndex: "100"
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: "13em",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "4px"
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "5px"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#C2C2C2",
            borderRadius: "4px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#C2C2C2"
        }
    }),
    placeholder: (base) => ({
        ...base,
        ...placeholderDefaultStyle
    })
};

const SelectComponent = ({ label, componentProps, options, value: __value, ...reactSelectProps }) => {
    const value = useMemo(
        function resolveValue() {
            if (typeof __value === "string") {
                // find option with the selected value
                return options && options.find((opt) => (opt.label || opt.name) === __value);
            }
            return __value;
        },
        [__value, options]
    );

    return (
        <div className='flex flex-col gap-1'>
            {label && (
                <div className="">
                    <label className='font-medium text-sm'>
                        {label}
                    </label>
                </div>
            )}
            <ReactSelect
                value={value}
                options={options}
                styles={customSelectStyle}
                {...reactSelectProps}
                components={{
                    IndicatorSeparator: () => null,
                    ...componentProps
                }}
            />
        </div>
    )
}

export default SelectComponent;