import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/layout/layout';
import Header from '../../components/banner';
import {
  BsCalendar4,
  BsClock,
  BsFillCreditCardFill,
  BsFillStarFill,
} from 'react-icons/bs';
import { FiUser } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';
import starsfull from '../../assets/icons/starsfull.svg';
import { useDispatch, useSelector } from 'react-redux';
// import { getProfile } from '../../features/experts/expertSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import ReactTimeAgo from 'react-time-ago';
import { getEnvVars } from '../../app/env';
import { setDashGuide } from '../../features/app/appSlice';
import plusIcon from '../../assets/icons/plus-circle.svg';
import Container from '../../components/shared/Container';

const { REACT_APP_API_URL, REACT_APP_FF_AD_IMAGES } = getEnvVars();

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { expert } = useSelector((state) => state.expert);
  const { dashGuide } = useSelector((state) => state.app);
  const [reviews, setReviews] = useState([]);
  const [calls, setCalls] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [token /*setToken*/] = useState(() => localStorage.exptToken);
  const [loading, setLoading] = useState(false);
  const [cta, setCta] = useState({
    payment: false,
    calendar: false,
  });
  const [guideState, setGuideState] = useState(
    localStorage.getItem('expertGuide'),
  );
  const sectionRefs = useRef([]);

  const currentDate = new Date();
  const currentDateISO = currentDate.toISOString();
  currentDate.setHours(24);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);
  currentDate.setMilliseconds(999);
  const endDateISO = currentDate.toISOString();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleStorageChange = (event) => {
    if (event.key === 'expertGuide') {
      setGuideState(event.newValue);
    }
  };

  useEffect(() => {
    // Set a timeout to delay the initial scroll
    const timeoutId = setTimeout(() => {
      if (sectionRefs.current[dashGuide]) {
        // sectionRefs.current[dashGuide].scrollIntoView({ behavior: 'smooth' });
        const section = sectionRefs.current[dashGuide];
        const topOffset = section.offsetTop;
        const height = section.clientHeight;
        const windowHeight = window.innerHeight;
        const targetScrollPosition = topOffset - (windowHeight - height) / 2;
        window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
      }
    }, 2000); // Adjust the timeout duration as needed

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array, runs only on mount

  useEffect(() => {
    // Scroll to the section referenced by dashGuide
    setGuideState(localStorage.getItem('expertGuide') || '0');

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dashGuide]);

  // Function to update guideState and localStorage
  const updateGuideState = (newValue) => {
    if (parseInt(newValue, 10) >= 9) {
      setGuideState(null);
      localStorage.removeItem('expertGuide');
    } else {
      setGuideState(newValue);
      localStorage.setItem('expertGuide', newValue);
    }
    dispatch(setDashGuide(newValue));
  };

  // useEffect(() => {
  //   dispatch(getProfile());
  // }, []);

  useEffect(() => {
    (async () => {
      const api = `${REACT_APP_API_URL}/v1/reviews/?expertId=${localStorage.getItem(
        'expertId',
      )}&limit=3&skip=0`;
      axios
        .get(api)
        .then((result) => {
          const reviews = result.data.data.docs;
          setReviews(reviews);
        })
        .catch(function (error) {
          console.error(error);
        });
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const api = `${REACT_APP_API_URL}/v1/sessions/me`;
      axios
        .get(api, { headers })
        .then((result) => {
          const calls = result.data.data.docs;
          setLoading(false);
          // Filter out done calls from call sessions.
          setCalls(calls.filter((item) => item.status !== 'completed'));
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
    })();
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const api = `${REACT_APP_API_URL}/v1/sessions/me?orderBy=-startsAt&from=${currentDateISO}&to=${endDateISO}`;
      axios
        .get(api, { headers })
        .then((result) => {
          const schedule = result.data.data.docs;
          setLoading(false);
          setSchedule(schedule);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
    })();
  }, []);

  useEffect(() => {
    if (guideState === '0') {
      updateGuideState('1');
    } else {
      dispatch(setDashGuide(parseInt(guideState, 10)));
    }
  }, [guideState, dispatch]);

  useEffect(() => {
    if (expert?.hasSetBankDetails === true && parseInt(guideState, 10) < 3) {
      updateGuideState('3');
    }
  }, [expert]);

  useEffect(() => {
    if (expert && expert?.hasSetBankDetails === false) {
      setCta({ ...cta, payment: true });
    }
    if (expert && expert?.minutesPerUnitSession <= 15) {
      setCta({ ...cta, calendar: true });
    }
  }, [expert]);

  const options = {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  };

  const duration = {
    hour: 'numeric',
    minute: 'numeric',
  };

  const removeGuide = () => {
    dispatch(setDashGuide(null));
    localStorage.setItem('expertGuide', null);
  };

  // const handleNextGuide = (nextGuide) => {
  //   updateGuideState(nextGuide.toString());
  // };

  const handleNextGuide = (nextGuide) => {
    updateGuideState(nextGuide.toString());
    // Scroll to the section referenced by dashGuide
    if (sectionRefs.current[nextGuide]) {
      // sectionRefs.current[nextGuide].scrollIntoView({ behavior: 'smooth' });
      const section = sectionRefs.current[nextGuide];
      const topOffset = section.offsetTop;
      const height = section.clientHeight;
      const windowHeight = window.innerHeight;
      const targetScrollPosition = topOffset - (windowHeight - height) / 2;
      window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' });
    }
  };

  const handleDashGuideNavigation = (nextGuide, path) => {
    navigate(path);
    handleNextGuide(nextGuide);
  };

  const getStatus = (session) => {
    const sessionIsBookedAndPaid =
      (session?.isScheduled &&
        session?.status === 'accepted' &&
        session?.url) ||
      !session.isScheduled;

    if (session.status === 'completed') {
      return 'Done';
    }
    if (session.status === 'accepted' && !session.paymentId) {
      return 'Unpaid';
    }
    if (session?.status === 'cancelled') {
      return 'Cancelled';
    }
    if (sessionIsBookedAndPaid) {
      return 'Booked';
    }
    if (session?.isScheduled && session?.status === 'accepted') {
      return 'Accepted';
    }
    if (session?.isScheduled) {
      return 'Requested';
    }
    if (session?.status === 'declined') {
      return 'Declined';
    }

    return 'Booked';
  };

  const statusShed = (session) => {
    if (session.status === 'completed') {
      return 'bg-gray-300';
    } else if (session.status === 'accepted' && !session.paymentId) {
      return 'text-blue-600 bg-blue-100';
    } else if (session?.isScheduled && session?.status === 'pending') {
      return 'bg-[#FFEFD2] text-[#BA8800]';
    } else if (
      (session?.isScheduled && session?.status === 'declined') ||
      session?.status === 'cancelled'
    ) {
      return 'bg-[#FFEDE9] text-[#BA1B1B]';
    } else {
      return 'text-green-600 bg-green-100';
    }
  };

  const handleSessionRedirect = (callSession) => {
    if (callSession.status === 'completed') {
      return;
    } else if (callSession.status === 'accepted' && !callSession.paymentId) {
      navigate(`/book/${callSession.id}/payment`);
    } else {
      navigate(
        `${location.pathname.includes('expert')
          ? '/expert/call/detail/' + callSession?.id
          : '/call/detail/' + callSession?.id
        }`,
      );
    }
  };

  return (
    <ProtectedRoute>
      <Layout>
        <Header title="Dashboard" pathUrl="/expert/dashboard" />
        <Container>
          <div className="grid grid-cols-12 gap-x-6">
            <div className="col-span-12 lg:col-span-7 flex flex-col space-y-12">
              <div className="grid md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-6 ">
                <div
                  className="relative"
                  ref={(el) => (sectionRefs.current[3] = el)}
                >
                  <div className="bg-white sm:p-6 p-5 rounded-lg sm:rounded-2xl shadow-md h-max">
                    <div className="flex items-start gap-x-3">
                      <BsFillCreditCardFill className="text-purple-600 text-2xl sm:text-3xl" />
                      <div className="grid -mt-1 sm:mt-0">
                        <p className="font-semibold text-2xl sm:text-[28px]">
                          {`$${expert?.balance ? expert.balance : '0'}`}
                        </p>
                        <small className="text-neutral-400">
                          Account balance
                        </small>
                      </div>
                    </div>

                    <p
                      className="font-medium pt-5 cursor-pointer text-neutral-400"
                      onClick={() => navigate('/expert/account/payment')}
                    >
                      Withdraw funds
                    </p>
                  </div>

                  {dashGuide === 3 && expert && (
                    <div className="absolute top-0 left-0 right-0 z-30 ">
                      <div className="bg-white sm:p-6 p-5 rounded-lg sm:rounded-2xl shadow-md h-max">
                        <div className="flex items-start gap-x-3">
                          <BsFillCreditCardFill className="text-purple-600 text-2xl sm:text-3xl" />
                          <div className="grid -mt-1 sm:mt-0">
                            <p className="font-semibold text-2xl sm:text-[28px]">
                              {`$${expert?.balance ? expert.balance : '0'}`}
                            </p>
                            <small className="text-neutral-400">
                              Account balance
                            </small>
                          </div>
                        </div>

                        <p
                          className="font-medium pt-5 cursor-pointer text-neutral-400"
                          onClick={() =>
                            handleDashGuideNavigation(
                              4,
                              '/expert/account/payment',
                            )
                          }
                        >
                          Withdraw funds
                        </p>
                      </div>

                      <div className="bg-white w-full md:w-[130%] p-6 mt-4 rounded-lg sm:rounded-2xl">
                        <h2 className="font-semibold text-2xl">
                          See how much you have made
                        </h2>

                        <p className="my-3">
                          View your earnings from calls here
                        </p>

                        <div className="flex align-middle space-x-4 items-center">
                          <button
                            className="text-[#792AE2] font-semibold mr-auto"
                            onClick={() => handleNextGuide(2)}
                          >
                            Previous
                          </button>

                          <button
                            className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                            onClick={() => handleNextGuide(4)}
                          >
                            Next
                          </button>

                          <button
                            className="text-[#792AE2] font-semibold"
                            onClick={removeGuide}
                          >
                            Skip
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="relative"
                  ref={(el) => (sectionRefs.current[4] = el)}
                >
                  <div className="bg-white sm:p-6 p-5 rounded-lg sm:rounded-2xl shadow-md h-max">
                    <div className="flex items-start gap-x-3">
                      <BsFillStarFill className="text-[#FDBC05] text-2xl sm:text-3xl" />
                      <div className="grid -mt-1 sm:mt-0">
                        <p className="font-semibold text-2xl sm:text-[28px]">
                          {expert?.rating ? expert?.rating : '0'}
                        </p>
                        <small className="text-neutral-400">User rating</small>
                      </div>
                    </div>

                    <p
                      className="text-purple-600 font-medium pt-5 cursor-pointer"
                      onClick={() => navigate('/expert/account/profile')}
                    >
                      View ratings
                    </p>
                  </div>

                  {dashGuide === 4 && (
                    <div className="absolute top-0 left-0 w-full z-30">
                      <div className="bg-white sm:p-6 p-5 rounded-lg sm:rounded-2xl shadow-md h-max">
                        <div className="flex items-start gap-x-3">
                          <BsFillStarFill className="text-[#FDBC05] text-2xl sm:text-3xl" />
                          <div className="grid -mt-2">
                            <p className="font-semibold text-2xl sm:text-[28px]">
                              {expert?.rating ? expert?.rating : '0'}
                            </p>
                            <small className="text-neutral-400">
                              User rating
                            </small>
                          </div>
                        </div>

                        <p
                          className="text-purple-600 font-medium pt-5 cursor-pointer"
                          onClick={() =>
                            handleDashGuideNavigation(
                              5,
                              '/expert/account/profile',
                            )
                          }
                        >
                          View ratings
                        </p>
                      </div>

                      <div className="bg-white w-full lg:w-[140%] p-6 mt-4 rounded-lg sm:rounded-2xl">
                        <h2 className="font-semibold text-2xl">
                          See how well users think you are doing
                        </h2>

                        <p className="my-3">View your call ratings here.</p>

                        <div className="flex align-middle space-x-4 items-center">
                          <button
                            className="text-[#792AE2] font-semibold mr-auto"
                            onClick={() => handleNextGuide(3)}
                          >
                            Previous
                          </button>

                          <button
                            className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                            onClick={() => handleNextGuide(5)}
                          >
                            Next
                          </button>

                          <button
                            className="text-[#792AE2] font-semibold"
                            onClick={removeGuide}
                          >
                            Skip
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="relative"
                ref={(el) => (sectionRefs.current[6] = el)}
              >
                <div>
                  <p className="font-semibold text-[28px]">Upcoming calls</p>
                  {calls.length !== 0 ? (
                    <>
                      {calls.slice(0, 3).map((item, key) => (
                        <div
                          className="sm:rounded-2xl rounded-xl my-4 grid gap-4 shadow-sm p-5 sm:p-7 bg-white"
                          key={key + item.id}
                          onClick={() => handleSessionRedirect(item)}
                        >
                          <div className="">
                            <div className="flex justify-between items-center">
                              <span
                                className={`${statusShed(
                                  item,
                                )} sm:py-1.5 py-1 sm:px-4 px-3 text-xs sm:text-sm sm:rounded-2xl rounded-xl w-min`}
                                id="btnStatus"
                              >
                                {getStatus(item)}
                              </span>

                              <div className="text-neutral-500 text-sm ml-auto items-end sm:hidden">
                                {item?.updatedAt && (
                                  <ReactTimeAgo
                                    date={item?.updatedAt}
                                    locale="en-US"
                                  />
                                )}
                              </div>
                            </div>

                            <p className="text-xl sm:text-2xl mt-2.5 mb-2">
                              {item.title}
                            </p>

                            <div className="flex gap-x-4 items-center">
                              <div className="flex items-center space-x-1">
                                <BsCalendar4 className="text-neutral-500 mr-1 self-center" />
                                <p className='text-xs sm:text-sm md:text-base'>
                                  {item.startsAt?.length > 0 &&
                                    new Date(item.startsAt).toLocaleDateString(
                                      'en-US',
                                      options,
                                    )}
                                </p>
                              </div>
                              <div className="flex items-center space-x-1">
                                <BsClock className="text-neutral-500 mr-1 self-center" />
                                <p className='text-xs sm:text-sm md:text-base'>
                                  {item.startsAt?.length > 0 &&
                                    new Date(item.startsAt).toLocaleString(
                                      'en-US',
                                      duration,
                                    )}{' '}
                                  -
                                  {item.startsAt?.length > 0 &&
                                    new Date(item.endsAt).toLocaleString(
                                      'en-US',
                                      duration,
                                    )}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center gap-x-4">
                            {item?.participant?.profilePhoto ? (
                              <div className="w-14 h-14 rounded-full object-cover">
                                <img
                                  src={item?.participant?.profilePhoto}
                                  alt=""
                                  className="w-full h-full rounded-full"
                                />
                              </div>
                            ) : (
                              <div className=" bg-gray-100 w-14 h-14 rounded-full flex justify-center align-middle items-center">
                                <FiUser className="text-[#270058]" size={28} />
                              </div>
                            )}

                            <div>
                              <p>
                                {item?.participant?.firstName +
                                  ' ' +
                                  item?.participant?.lastName}
                              </p>
                              <p className="text-neutral-500">
                                {item?.participant?.jobTitle}
                              </p>
                            </div>

                            <div className="text-neutral-500 text-sm ml-auto items-end hidden sm:flex">
                              {item?.updatedAt && (
                                <ReactTimeAgo
                                  date={item?.updatedAt}
                                  locale="en-US"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : loading ? (
                    <>
                      <div className="grid rounded-2xl shadow-sm card mt-4">
                        <div
                          role="status"
                          className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                        >
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                        </div>
                      </div>
                      <div className="grid rounded-2xl shadow-sm card my-4">
                        <div
                          role="status"
                          className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                        >
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                        </div>
                      </div>
                      <div className="grid rounded-2xl shadow-sm card">
                        <div
                          role="status"
                          className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                        >
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                          <div className="h-7 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="text-sm text-gray-500 py-6">
                      You have no upcoming calls yet.
                    </div>
                  )}
                </div>

                {dashGuide === 6 && (
                  <div className="absolute top-0 left-0 w-full z-30">
                    <div>
                      <p className="font-semibold text-[28px] text-white">
                        Upcoming calls
                      </p>

                      <div className="text-sm text-white py-6">
                        You have no upcoming calls yet.
                      </div>
                    </div>

                    <div className="bg-white p-6 mt-4 sm:max-w-[28rem] rounded-lg sm:rounded-2xl">
                      <h2 className="font-semibold text-2xl">
                        See all your other calls
                      </h2>

                      <p className="my-3">View your upcoming calls here.</p>

                      <div className="flex align-middle space-x-4 items-center mt-8">
                        <button
                          className="text-[#792AE2] font-semibold mr-auto"
                          onClick={() => handleNextGuide(5)}
                        >
                          Previous
                        </button>

                        <button
                          className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                          onClick={() => handleNextGuide(7)}
                        >
                          Next
                        </button>

                        <button
                          className="text-[#792AE2] font-semibold"
                          onClick={removeGuide}
                        >
                          Skip
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="relative"
                ref={(el) => (sectionRefs.current[7] = el)}
              >
                <div>
                  <p className="font-semibold text-[28px]">Customer feedback</p>
                  {reviews.length !== 0 ? (
                    <>
                      {reviews.map((review, key) => (
                        <div
                          className="rounded-lg sm:rounded-2xl p-4 sm:p-8 my-4 bg-white"
                          key={key + review.id}
                        >
                          <div className="flex">
                            <div className="bg-gray-300 rounded-full p-2.5">
                              <FiUser size={25} className="text-purple-900" />
                            </div>
                            <div className="grid pl-4">
                              <h5 className="font-semibold text-xl">
                                {review?.client?.firstName +
                                  ' ' +
                                  review?.client?.lastName}
                              </h5>
                              <div className="flex">
                                <img src={starsfull} alt="rating-icon" />
                                {review?.rating}
                                <p className="text-gray-400 font-normal text-xs self-center ml-3">
                                  {review &&
                                    new Date(
                                      review?.updatedAt,
                                    ).toLocaleDateString('en-US', {
                                      day: 'numeric',
                                      year: 'numeric',
                                      month: 'long',
                                    })}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="pt-4">{review?.comment}</p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="text-sm text-gray-500 py-6">
                      You have no customers feedback yet.
                    </div>
                  )}
                </div>

                {dashGuide === 7 && (
                  <div className="absolute top-0 left-0 w-full z-30">
                    <div>
                      <p className="font-semibold text-[28px] text-white">
                        Customer feedback
                      </p>

                      <div className="text-sm text-white py-6">
                        You have no customers feedback yet.
                      </div>
                    </div>

                    <div className="bg-white p-6 mt-4 sm:max-w-[28rem] rounded-lg sm:rounded-2xl">
                      <h2 className="font-semibold text-2xl">
                        See how they think the call went
                      </h2>

                      <p className="my-3">
                        View customer feedback from your call sessions here.
                      </p>

                      <div className="flex align-middle space-x-4 items-center mt-8">
                        <button
                          className="text-[#792AE2] font-semibold mr-auto"
                          onClick={() => handleNextGuide(6)}
                        >
                          Previous
                        </button>

                        <button
                          className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                          onClick={() => handleNextGuide(8)}
                        >
                          Next
                        </button>

                        <button
                          className="text-[#792AE2] font-semibold"
                          onClick={removeGuide}
                        >
                          Skip
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-span-12 mt-12 lg:mt-0 lg:col-span-5">
              <div
                className="relative"
                ref={(el) => (sectionRefs.current[5] = el)}
              >
                <div className="p-4 sm:px-6 py-8 rounded-lg sm:rounded-2xl bg-white shadow-md h-max">
                  {schedule.length !== 0 ? (
                    <>
                      <div className="pb-8">
                        <p>{"Today's schedule"}</p>
                        <small className="text-neutral-400">
                          {currentDate.toLocaleString('en-US', {
                            weekday: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            month: 'long',
                          })}
                        </small>
                      </div>

                      <div className="grid gap-y-8">
                        {schedule.slice(0, 3).map((review, key) => (
                          <>
                            <div className="grid grid-cols-5" key={key}>
                              <div className="">
                                {review.startsAt?.length > 0 &&
                                  new Date(review?.startsAt).toLocaleString(
                                    'en-US',
                                    duration,
                                  )}{' '}
                                <br />
                                <small className="text-neutral-400">
                                  {review?.startsAt?.length > 0 &&
                                    review?.endsAt?.length > 0 &&
                                    Math.floor(
                                      ((new Date(review?.endsAt).getTime() -
                                        new Date(review?.startsAt).getTime()) %
                                        (1000 * 60 * 60)) /
                                      (1000 * 60),
                                    )}
                                  {'mins '}
                                </small>
                              </div>
                              <div
                                className={`mx-auto h-auto w-1 ${review.status === 'accepted'
                                  ? 'bg-green-600'
                                  : 'bg-orange-400'
                                  }`}
                              ></div>
                              <div className="col-span-3">
                                {review.title} <br />
                                <small className="text-neutral-400">
                                  {review.participant.firstName +
                                    ' ' +
                                    review.participant.lastName}
                                </small>
                              </div>
                            </div>
                          </>
                        ))}
                        <p
                          className="text-purple-600 grid cursor-pointer"
                          onClick={() =>
                            navigate('/expert/account/availability')
                          }
                        >
                          Availabiliy settings
                        </p>
                      </div>
                    </>
                  ) : (
                    <div>
                      <h2 className="font-bold text-xl mb-2">
                        Today’s schedule
                      </h2>
                      <p className="my-1 text-sm font-light text-textLight">
                        You currently have nothing scheduled yet.
                      </p>
                      <p className="text-sm font-light text-textLight">
                        Your schedules will be shown here when you have any.
                      </p>
                    </div>
                  )}
                </div>

                {dashGuide === 5 && (
                  <div className="absolute top-0 left-0 w-full z-30">
                    <div className="p-4 sm:px-6 py-8 rounded-lg sm:rounded-2xl bg-white shadow-md h-max">
                      <div>
                        <h2 className="font-bold text-xl mb-2">
                          Today’s schedule
                        </h2>
                        <p className="my-1 text-sm font-light text-textLight">
                          You currently have nothing scheduled yet.
                        </p>
                        <p className="text-sm font-light text-textLight">
                          Your schedules will be shown here when you have any.
                        </p>
                      </div>
                    </div>

                    <div className="bg-white p-6 mt-4 rounded-lg sm:rounded-2xl">
                      <h2 className="font-semibold text-2xl">
                        Your call schedule at a glance
                      </h2>

                      <p className="my-3">
                        View your Zedintro call schedule for the day here.
                      </p>

                      <div className="flex align-middle space-x-4 items-center mt-8">
                        <button
                          className="text-[#792AE2] font-semibold mr-auto"
                          onClick={() => handleNextGuide(4)}
                        >
                          Previous
                        </button>

                        <button
                          className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                          onClick={() => handleNextGuide(6)}
                        >
                          Next
                        </button>

                        <button
                          className="text-[#792AE2] font-semibold"
                          onClick={removeGuide}
                        >
                          Skip
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {!cta.payment && (
                <div
                  className="relative"
                  ref={(el) => (sectionRefs.current[2] = el)}
                >
                  <div className="bg-[#792AE2] p-8 rounded-2xl h-max mt-6 shadow-sm text-white relative">
                    <IoClose
                      className="float-right cursor-pointer text-3xl"
                      onClick={() => setCta({ ...cta, payment: false })}
                    />
                    <p className="font-semibold text-3xl">Payment settings</p>
                    <p className="mt-2 mb-14 max-w-[22rem]">
                      So we can send you money you have made with us, please add
                      your bank account.
                    </p>
                    <button
                      className="btn-white"
                      onClick={() => navigate('/expert/account/payment')}
                    >
                      Connect bank account
                    </button>
                    <img
                      src={plusIcon}
                      alt="plus-icon"
                      className="absolute right-0 bottom-0"
                    />
                  </div>

                  {dashGuide === 2 && (
                    <div className="absolute top-0 left-0 w-full z-30">
                      <div className="bg-[#792AE2] p-8 rounded-2xl h-max shadow-sm text-white relative">
                        <IoClose
                          className="float-right cursor-pointer text-3xl"
                          onClick={() => handleNextGuide(3)}
                        />
                        <p className="font-semibold text-3xl">
                          Payment settings
                        </p>
                        <p className="mt-2 mb-14 max-w-[22rem]">
                          So we can send you money you have made with us, please
                          add your bank account.
                        </p>
                        <button
                          className="btn-white"
                          onClick={() => navigate('/expert/account/payment')}
                        >
                          Connect bank account
                        </button>
                        <img
                          src={plusIcon}
                          alt="plus-icon"
                          className="absolute right-0 bottom-0"
                        />
                      </div>

                      <div className="bg-white p-6 mt-4 rounded-2xl">
                        <h2 className="font-semibold text-2xl">
                          Get paid for your time
                        </h2>

                        <p className="my-3">Set up your payment details here</p>

                        <div className="flex align-middle space-x-4 items-center">
                          <button
                            className="text-[#792AE2] font-semibold mr-auto"
                            onClick={() => handleNextGuide(1)}
                          >
                            Previous
                          </button>

                          <button
                            className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow ml-auto"
                            onClick={() => handleNextGuide(3)}
                          >
                            Next
                          </button>

                          <button
                            className="text-[#792AE2] font-semibold"
                            onClick={removeGuide}
                          >
                            Skip
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {cta.calendar && (
                <div
                  className="relative"
                  ref={(el) => (sectionRefs.current[1] = el)}
                >
                  <div className="bg-[#792AE2] p-8 rounded-2xl h-max mt-6 shadow-sm text-white relative">
                    <IoClose
                      className="float-right cursor-pointer text-3xl"
                      onClick={() => setCta({ ...cta, calendar: false })}
                    />
                    <p className="font-semibold text-3xl">
                      Availability settings
                    </p>
                    <p className="mt-2 mb-14 max-w-[22rem]">
                      So we can let your clients know when you are available to
                      be booked for a call.
                    </p>
                    <button
                      className="btn-white"
                      onClick={() => navigate('/expert/account/availability')}
                    >
                      Set your availability
                    </button>
                    <img
                      src={plusIcon}
                      alt="plus-icon"
                      className="absolute right-0 bottom-0"
                    />
                  </div>

                  {dashGuide === 1 && (
                    <div className="absolute bottom-0 left-0 w-full z-30">
                      <div className="bg-white p-6 mb-4 rounded-2xl">
                        <h2 className="font-semibold text-2xl">
                          Welcome to your Zedintro Dashboard
                        </h2>
                        <p className="my-3">
                          You can connect your calendar here for users to see
                          your availability.
                        </p>
                        <div className="flex align-middle justify-end space-x-4 items-center">
                          <button
                            className="bg-bgPurple text-white px-4 py-1.5 rounded-md shadow"
                            onClick={() => handleNextGuide(2)}
                          >
                            Next
                          </button>

                          <button
                            className="text-[#792AE2] font-semibold"
                            onClick={removeGuide}
                          >
                            Skip
                          </button>
                        </div>
                      </div>

                      <div className="bg-[#792AE2] p-8 rounded-2xl h-max mt-6 shadow-sm text-white relative">
                        <IoClose
                          className="float-right cursor-pointer text-3xl"
                          onClick={() => handleNextGuide(2)}
                        />
                        <p className="font-semibold text-3xl">
                          Availability settings
                        </p>
                        <p className="mt-2 mb-14 max-w-[22rem]">
                          So we can let your clients know when you are available
                          to be booked for a call.
                        </p>
                        <button
                          className="btn-white"
                          onClick={() =>
                            navigate('/expert/account/availability')
                          }
                        >
                          Set your availability
                        </button>
                        <img
                          src={plusIcon}
                          alt="plus-icon"
                          className="absolute right-0 bottom-0"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {REACT_APP_FF_AD_IMAGES === 'true' && (
                <div className="bg-white flex items-center justify-center font-bold text-3xl min-h-[12rem] p-4 sm:px-6 py-8 rounded-lg mt-8 sm:rounded-2xl shadow-md">
                  ADS IMAGE
                </div>
              )}
            </div>
          </div>
        </Container>
      </Layout>
    </ProtectedRoute>
  );
};

export default Dashboard;
